import { Lesson } from './lesson.interface';

export interface LessonBundle {
  /**
   * ID
   */
  id?: number;
  /**
   * Name des Pakets
   */
  label: string;
  /**
   * Einstellungen
   */
  setting?: any;
  /**
   * Kurspaket empfohlen?
   */
  isrecommended?: boolean;
  /**
   * Bilder
   */
  mediaitems?: any[];
  /**
   * Kurse innerhalb des Pakets
   */
  lessons?: Lesson[];
  /**
   * Kurs
   */
  lessonids?: number[];
}
