import { Address } from './address.interface';
import { Setting } from './setting.interface';
import { User } from '../../_root-store/user-store/user.state';

export interface Organisation {
  /**
   * ID
   */
  id: number;
  /**
   * Name der Firma
   */
  label: string;
  /**
   * Rechnungsname
   */
  billinglabel?: any;
  /**
   * Namenszusatz
   */
  additional: string;
  /**
   * Abteilung
   */
  department: string;
  /**
   * UStID
   */
  ustid: string;
  /**
   * Notiz
   */
  infotext: string;
  /**
   * Erzeugt am
   */
  created: Date;
  /**
   * Bearbeitet am
   */
  modified: Date;
  /**
   * Erzeugt von
   */
  created_by: number;
  /**
   * Bearbeitet von
   */
  modified_by: number;
  /**
   * ist Niederlassung von
   */
  parent_id?: any;
  /**
   * Einstellungen
   */
  setting: Setting;
  /**
   * Einstellungs ID
   */
  setting_id: number;
  /**
   * Hauptniederlassung oder Zweigstelle
   */
  level: number;

  lft: number;
  rght: number;

  /**
   * Hat erweiterte Statistiken
   */
  isstatistics: boolean;
  /**
   * ID des Firmenadmin
   */
  user_id?: any;
  /**
   * Mitarbeiter
   */
  users: User[];
  /**
   * Importquelle
   */
  importsource?: any;
  /**
   * Customization Data. Farben, Logo etc.
   */
  customization?: any;
  /**
   * Adressen.
   */
  addresses: Address[];
  /**
   * Hinterlegte Dokumente
   */
  documents: any[];
  /**
   * Adminname
   */
  adminlabel: string;
  /**
   * Anschrift
   */
  address: Address;
  /**
   * Rechnungsanschrift
   */
  billingaddress: Address;
}
