import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest, map, Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { IdentityService, LoginState, SystemService } from './utility';
import { defaultMenuData, MenuData } from './utility/constants/default-menu-data.constants';
import { RESTService } from './utility/services/rest/rest.service';

@Component ( {
    selector   : 'lcms-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
} )
export class AppComponent implements OnInit {
    LoginState = LoginState;
    menuData!: Observable<MenuData[]>;

    constructor(
        public identityService: IdentityService,
        public restService: RESTService,
        public systemService: SystemService,
        private snack: MatSnackBar,
    ) {
      if(environment.e2e) {
        window.alert('In this env, LCMS e2e webservice is used(except Brainfood). This api is meant for clients\' e2e tests. Do not CRUD the data set, otherwise the e2e tests might fail. Change only when you are sure about its impact. Use Sebastian\'s credentials to login.')
      }
    }

    ngOnInit(): void {
        this.filterMenuItems ();

        this.restService.error
            .pipe (
                filter ( err => !!err ),
            )
            .subscribe ( ( error ) => {
                this.errorHandle ( error );
            } );

        this.restService.messages
            .pipe (
                filter ( err => !!err ),
            )
            .subscribe ( ( m ) => {
                this.messageHandle ( m );
            } );
    }

    private errorHandle( error: any ): void {

        if ( error.error.messages ) {
            error.error.messages.forEach ( ( message: any, index: number ) => {
                this.messageHandle ( message );
            } );
        }
    }

    private messageHandle( message: { level: number, text: string } ): void {
        switch ( message.level ) {
            case 0:
            case 1:
            case 2:
                this.snack.open ( message.text, undefined, {
                    verticalPosition  : 'bottom',
                    horizontalPosition: 'center',
                    duration          : 2500,
                } );
                break;

            case 3:
                this.snack.open ( message.text, 'OK', {
                    verticalPosition  : 'top',
                    horizontalPosition: 'end',
                    duration          : 3500,
                } )
                    .afterOpened ()
                    .subscribe ( () => {
                        this.identityService.logout ();
                    } );
                break;
        }
    }

    filterMenuItems(): void {
        this.menuData = combineLatest ( [
            of ( defaultMenuData ),
            this.identityService.role,
        ] )
            .pipe (
                map ( ( [menuData, role] ) => {
                    const filteredMenuItems: MenuData[] = [];
                    const roleAccessLevel               = environment.roles[ role - 1 ];

                    if ( roleAccessLevel ) {
                        menuData.forEach ( menuCategoryBlock => {
                            const filteredItems = menuCategoryBlock.categoryItems.filter ( menuItem => {
                                return roleAccessLevel.includes ( menuItem.id );
                            } );

                            filteredMenuItems.push ( {
                                categoryTitle: menuCategoryBlock.categoryTitle,
                                categoryItems: filteredItems,
                            } );
                        } );
                    }

                    return filteredMenuItems;
                } ),
            );
    }
}
