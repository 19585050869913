export interface SaveData {
  /**
   * Nutzername/Email
   */
  username?: string;
  /**
   * Passwort
   */
  password?: string;
  /**
   * Bearer Token
   */
  token: string;
  /**
   * Userobject
   */
  user?: {
    firstname: string;
    surname: string;
    role: {
      id: number;
      label: string;
    };
    id: string;
  }
}
