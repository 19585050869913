import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LCMSConstants } from '../constants/lcms.constants';
import { Author } from '../models/author.interface';
import { BaseService } from './base-service';
import { RESTService } from './rest/rest.service';

@UntilDestroy ( { checkProperties: true } )
@Injectable ( {
    providedIn: 'root',
} )
export class AuthorsService implements BaseService{
    readonly authors: BehaviorSubject<Author[]> = new BehaviorSubject<Author[]> ( [] );

    readonly author: BehaviorSubject<Author | undefined> = new BehaviorSubject<Author | undefined> ( undefined );

    constructor(
        private rest: RESTService,
    ) {
    }

    fetchAuthors(): Observable<Author[]> {
        return this.rest.addRequest ( {
            method  : RESTService.GET,
            endpoint: LCMSConstants.AUTHORS,
        } )
                   .pipe (
                       map ( ( arr ) => arr.sort ( ( a: Author, b: Author ) => a.id > b.id ? 1 : - 1 ) ),
                       tap ( authors => {
                           authors.forEach ( author => author.label = author.firstname + ' ' + author.lastname );
                           this.authors.next ( authors );
                       } ),
                   );
    }

    loadDetails( id: number | string ): Observable<Author> {
        return this.rest.addRequest ( {
            method  : RESTService.GET,
            endpoint: LCMSConstants.AUTHORS + '/' + id,
        } )
                   .pipe (
                       tap ( author => this.author.next ( author ) ),
                   );
    }

    saveDetails( author: Author ): Observable<any> {
        return this.rest.addRequest ( {
            method  : RESTService.PUT,
            endpoint: LCMSConstants.AUTHORS + '/' + author.id,
            payload : author,
        } );
    }

    addAuthor( author: Author ): Subject<any> {
        return this.rest.addRequest ( {
            method  : RESTService.POST,
            endpoint: LCMSConstants.AUTHORS,
            payload : {
                firstname: author.firstname,
                lastname : author.lastname,
            },
        } );
    }

    deleteAuthor( author: Author ): Observable<any> {
        return this.rest.addRequest ( {
            method  : RESTService.DELETE,
            endpoint: LCMSConstants.AUTHORS + '/' + author.id,
        } );
    }

    /**
     * get a list of all authros for usage in FieldTypes.SELECTION
     * i.e.:
     * [
     * {
     *   label : "Keiner",
     *   value : null
     * },
     * {
     *   label : "Autor 1",
     *   value : 1
     * },
     * ]
     */
    getListOfAuthors(): { label: string, value: number | null }[] {
        let authors: { label: string; value: number | null }[] = [];
        authors.push ( {
            label: 'Keiner',
            value: null,
        } );
        authors = authors.concat ( this.authors.value.map ( ( author: Author ) => {
                    return {
                        label: author.label + ' (#' + author.id + ')',
                        value: author.id,
                    };
                },
            ),
        );
        return authors;
    }

    addEntity( entity: any ): Observable<any> {
       return this.addAuthor(entity)
    }

    deleteEntity( entity: any ): Observable<any> {
     return this.deleteAuthor(entity)
    }
}
