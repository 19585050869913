import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { LCMSConstants } from '../constants/lcms.constants';
import { Section } from '../models/section.interface';
import { SystemTextItem } from '../models/system-text-item.interface';
import { RESTService } from './rest/rest.service';

@UntilDestroy ( { checkProperties: true } )
@Injectable ( {
    providedIn: 'root',
} )
export class SystemTextService {

    /**
     * Alle Systemtexteinträge
     */
    readonly localisation: BehaviorSubject<SystemTextItem[]> = new BehaviorSubject<any> ( null );

    /**
     * Verfügbar Sektionen. Sektionen sind quasi die Kategorien in den Systemtexten, um das ganze ein wenig zu strukturieren
     */
    readonly sections: BehaviorSubject<Section[]> = new BehaviorSubject<Section[]> ( [] );

    /**
     * Aktuell genutzte Sprachen innerhalb der Systemtexte
     */
    readonly lang: BehaviorSubject<Set<string | undefined>> = new BehaviorSubject<Set<string | undefined>> ( new Set () );

    /**
     * Anzahl der Sprachen im System
     */
    langCount = 0;

    constructor( private rest: RESTService ) {

    }

    /**
     * Methode um aktuell verfügbare Sektionen zu setzen und vorher zu prüfen, ob in einer Sektion
     * Text bzw. übersetzungen fehlen.
     * @param sections
     * @private
     */
    private setSections( sections?: any ): void {
        let tempSections: Section[] = [];
        if ( sections ) {
            sections.forEach ( ( s: any ) => {
                tempSections.push ( {
                    label  : s,
                    id     : s,
                    missing: 0,
                } );
            } );
        } else {
            tempSections = this.sections.value;
        }

        tempSections.forEach ( ( s ) => s.missing = 0 );

        this.localisation.value.forEach ( ( loc ) => {
            // Prüfe für jeden eintrag, ob die anzahl der Übersetzungen kleiner als die Anzahl der Sprachen ist.
            if ( loc.translations.length < this.langCount ) {
                // Wenn ja, erhöhe den Zähler der Sektion um 1
                const correspondingSection = tempSections?.find ( ( s ) => s?.id === loc.section );
                if ( correspondingSection ) {
                    correspondingSection.missing ++;
                }
            }
        } );
        this.sections.next ( tempSections );

    }

    /**
     * Lade Systemtexte
     */
    load(): Observable<any> {
        return this.rest.addRequest ( {
            method  : RESTService.GET,
            endpoint: LCMSConstants.TEXTS,
        } )
                   .pipe (
                       tap ( value => {
                           this.localisation.next ( value );
                           const sections: Set<string> = new Set<string> ();
                           const lang: Set<string>     = new Set<string> ();
                           value.forEach ( ( entry: any ) => {
                               sections.add ( entry.section );
                               entry.translations.forEach ( ( t: any ) => lang.add ( t.language ) );
                           } );
                           this.langCount = lang.size;
                           this.setSections ( sections );
                           this.lang.next ( lang );
                       } ),
                   );
    }

    /**
     * Speichere ALLE Systemtexte
     */
    save(): Observable<any> {
        return this.rest.addRequest ( {
            method  : RESTService.PUT,
            endpoint: LCMSConstants.TEXT_SAVE,
            payload : this.localisation.value,
        } )
                   .pipe (
                       tap ( () => this.setSections () ),
                   ) as Observable<any>;
    }

    /**
     * Speichere einzelnen Eintrag
     * @param i
     */
    saveIdentifier( i: SystemTextItem ): Observable<any> {
        return this.rest.addRequest ( {
            method  : RESTService.PUT,
            endpoint: LCMSConstants.TEXTS + '/' + i.id,
            payload : i,
        } )
                   .pipe (
                       tap ( () => this.setSections () ),
                   );
    }

    /**
     * Lade einzelnen Eintrag
     * @param id
     */
    loadIdentifier( id: number ): Observable<SystemTextItem> {
        return this.rest.addRequest ( {
            method  : RESTService.GET,
            endpoint: LCMSConstants.TEXTS + '/' + id,
        } )
                   .pipe (
                       tap ( ( ident ) => {
                           const items                            = this.localisation.value;
                           const item = items.find ( ( val ) => val.id === ident.id );
                           if ( item ) {
                               for ( const k in ident ) { // tslint:disable-line
                                   // TODO
                                    // @ts-ignore
                                   item[ k ] = ident[ k ];
                               }
                           }

                           this.setSections ();
                       } ),
                   );
    }

    /**
     * Füge Eintrag hinzu
     * @param txt
     */
    addIdentifier( txt: SystemTextItem ): Observable<any> {
        return this.rest.addRequest ( {
            method  : RESTService.POST,
            endpoint: LCMSConstants.TEXTS,
            payload : txt,
        } ) as Observable<any>;

    }

    /**
     * Lösche Eintrag
     * @param id
     */
    deleteIdentifier( id: number ): void {
        this.rest.addRequest ( {
            method  : RESTService.DELETE,
            endpoint: LCMSConstants.TEXTS + '/' + id,
        } )
            .pipe (
                switchMap ( () => this.load () ),
            )
            .subscribe ( ( response ) => {

            } );
    }

    /**
     * Füge im Lokalen System eine Sprache hinzu, um sie zu befüllen. Wird erst übermittel wenn gespeichert wird
     * @param lang
     */
    addLanguageLocal( lang: string ): void {
        const languages: Set<string | undefined> = this.lang.value;
        languages.add ( lang );
        this.lang.next ( languages );
    }

    /**
     * Sprache entfernen, speichern und anschliessend alle Texte neu laden
     * @param lang
     */
    removeLanguage( lang: any ): void {
        this.localisation
            .pipe (
                tap ( response => {
                    response.forEach (
                        entry => {
                            entry.translations.forEach ( ( trans ) => {
                                if ( trans.language === lang ) {
                                    trans.delete = true;
                                }
                            } );
                            // entry.translations = entry.translations.filter ( trans => trans.language !== lang );
                        },
                    );
                    this.localisation.next ( response );
                } ),
                switchMap ( value => this.save () ),
            )
            .subscribe ( () => {
                this.load ();
            } );
    }

}
