export enum Languages {
  DE = 'de',
  EN = 'en',
  ES = 'es',
  II = 'it',
  PT = 'pt',
  RU = 'ru',
  PL = 'pl',
  GR = 'gr',
  TR = 'tr'
}
