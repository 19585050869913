import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractDialogComponent } from '../../utility/classes/abstract-dialog';
import { SystemService } from '../../utility/services/system.service';

@Component ( {
    selector   : 'lcms-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls  : ['./confirm-dialog.component.scss'],
} )
export class ConfirmDialogComponent extends AbstractDialogComponent {

    constructor(
        @Inject ( MAT_DIALOG_DATA ) public data: any,
        protected override  dialog: MatDialogRef<ConfirmDialogComponent>,
        protected override system: SystemService,
    ) {
        super ( system, dialog );
    }

    click( isConfirmed: boolean ): void {
        this.dialog.close ( { confirmed: isConfirmed } );
    }

}
