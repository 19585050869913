import { PageEvent } from '@angular/material/paginator/paginator';
import { Sort } from '@angular/material/sort/sort';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { companyAdapter, CompanyState } from './company.state';

export const {
               selectIds     : _selectCompanyDataIds,
               selectEntities: _selectCompanyEntities,
               selectAll     : _selectAllCompanies,
               selectTotal   : _selectCompanyTotal,
             } = companyAdapter.getSelectors ();

export const selectCompanyState = createFeatureSelector<CompanyState> ( 'company' );

export const selectCompanyIds = createSelector (
  selectCompanyState,
  _selectCompanyDataIds,
);

export const selectCompanyEntities = createSelector (
  selectCompanyState,
  _selectCompanyEntities,
);

export const selectAllCompanies = createSelector (
  selectCompanyState,
  _selectAllCompanies,
);

export const selectCompanyError = createSelector (
  selectCompanyState,
  ( state: CompanyState ): boolean => state.error,
);

export const selectCompanyLoading = createSelector (
  selectCompanyState,
  ( state: CompanyState ): boolean => state.loading,
);

export const selectCompanyTotal = createSelector (
  selectCompanyState,
  ( state: CompanyState ): number => state.total,
);

export const selectCompanyPage = createSelector (
  selectCompanyState,
  ( state: CompanyState ): PageEvent => state.page,
);

export const selectCompanyTerm = createSelector (
  selectCompanyState,
  ( state: CompanyState ): string[] => state.terms,
);

export const selectCompanySort = createSelector (
  selectCompanyState,
  ( state: CompanyState ): Sort => state.sort as Sort,
);


export const selectCompanySearchRequest = createSelector (
  selectCompanyState,
  ( state: CompanyState ) => {
    let urlParameters = "?";

    urlParameters += ("limit=" + state.page.pageSize);
    urlParameters += ("&page=" + (state.page.pageIndex + 1));

    if (state.sort && state.sort.active !== 'isActive') {
      urlParameters += ("&sort=" + state.sort.active + "&direction=" + state.sort.direction);
    } else if (state.sort) {
      urlParameters += ("&sort=" + 'setting.isactive' + "&direction=" + state.sort.direction);
    } else {
      urlParameters += ("&sort=id" + "&direction=asc");
    }

      if (state.terms.length > 0) {
          urlParameters += "&q="

          for (const term of state.terms) {
              urlParameters += (term + "|");
          }

          urlParameters = urlParameters.slice(0, -1);
      }

    return urlParameters;
  },
);
