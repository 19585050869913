import { MenuItem } from '../models/admin-frontend/menu-item.interface';
import { Menu } from '../enums/menu.enum';

export interface MenuData {
  categoryTitle: string;
  categoryItems: MenuItem[]
}

export const defaultMenuData: MenuData[] = [
  {
    categoryTitle: 'Benutzer',
    categoryItems: [
      {
        id: Menu.USER,
        label: 'Benutzer',
        description: 'Nutzerverwaltung',
        link: '/users',
        icon: 'person'
      },
      {
        id: Menu.COMPANY,
        label: 'Firmen',
        description: 'Firmenverwaltung',
        link: '/companies',
        icon: 'business'
      },
      {
        id: Menu.USERGROUPS,
        label: 'Benutzergruppen',
        description: 'Verschiedene Gruppen um Nutzer zu gruppieren',
        link: '/groups',
        icon: 'groups'
      }
    ]
  },
  {
    categoryTitle: 'Kurse',
    categoryItems: [
      {
        id: Menu.LESSONS,
        label: 'Kurse',
        description: 'Alle verfügbaren Kurse',
        link: '/lessons',
        icon: 'school'
      },
      {
        id: Menu.LESSONPATHS,
        label: 'Lernpfade',
        description: 'Lernpfade',
        link: 'lessonpaths',
        icon: 'collections_bookmark'
      },
      {
        id: Menu.BLENDEDLEARNING_LESSONPATH,
        label: 'Blended Learning',
        description: 'Blended Learning',
        link: 'blended-learning-lessonpaths',
        icon: 'blender'
      },
      {
        id: Menu.ASSIGNMENT,
        label: 'Zuweisung',
        description: 'Nutzern Lernpfade zuweisen',
        link: 'assignment',
        icon: 'assignment_ind'
      },
      {
        id: Menu.GLOSSARY,
        label: 'Glossar',
        description: 'Glossareinträge der Kurse',
        link: '/glossary',
        icon: 'local_library'
      }
    ],
  },
  {
    categoryTitle: 'Anpassungen',
    categoryItems: [
      {
        id: Menu.NEWS,
        label: 'News',
        description: 'Neuigkeiten/Blogposts',
        link: 'news',
        icon: 'rss_feed'
      },
      {
        id: Menu.HELP,
        label: 'Hilfe',
        description: 'Hilfeeinträge',
        link: 'help',
        icon: 'help_outline'
      },
      {
        id: Menu.AUTHORS,
        label: 'Autoren',
        description: 'Verwaltung der im Portal vorhandenen E-Training Autoren',
        link: '/authors',
        icon: 'contacts'
      },
      {
        id: Menu.PROVIDERS,
        label: 'Anbieter',
        description: 'Verwaltung der im Portal vorhandenen E-Training Anbieter',
        link: '/providers',
        icon: 'account_balance'
      },
      {
        id: Menu.SPONSORS,
        label: 'Sponsoren',
        description: '',
        link: '/sponsors',
        icon: 'sell'
      },
      {
        id: Menu.ROOMS,
        label: 'Standorte & Räume',
        description: '',
        link: '/locations',
        icon: 'place'
      },
      {
        id: Menu.HERO,
        label: 'Startseitenbanner',
        description: 'Großer Banner auf der Startseite',
        link: 'hero',
        icon: 'format_shapes'
      },
      {
        id: Menu.STATICCONTENT,
        label: 'Seiten',
        description: 'Statische Inhalte',
        link: 'pages',
        icon: 'auto_stories'
      },
      {
        id: Menu.PAGE_NEXT,
        label: 'Seiten (v2)',
        description: 'Statische Inhalte (v2)',
        link: 'pages-next',
        icon: 'auto_stories'
      },
      {
        id: Menu.SYSTEMTEXT,
        label: 'Systemtexte',
        description: 'Generelle Texte des Portals',
        link: 'texts',
        icon: 'assignment'
      }
    ]
  },
  {
    categoryTitle: 'Daten',
    categoryItems: [
      {
        id: Menu.STATS,
        label: 'Statistiken',
        description: 'Allgemeine Statistiken über das Portal',
        link: '/statistics',
        icon: 'query_stats'
      }
    ]
  }
]
