import {LessonPathChapter, LessonPathChapterBase} from './lesson-path-chapter.interface';
import {MediaItem} from './media-item.interface';
import {Setting} from './setting.interface';

export interface LessonPath extends Setting {
  /**
   * ID
   */
  id: number;
  /**
   * Name des Lernpfad
   */
  label: string;

  // Permalink
  slug: string;
  /**
   * Lernpfad empfohlen?
   */
  isrecommended?: boolean;

  isRecommendedFormatted: string;

  isActive: string;
  isactive: boolean;

  isprivate: boolean;

  isVisible: string;
  /**
   * Kapitel innerhalb des Lernpfades
   */
  chapters?: (LessonPathChapter | LessonPathChapterBase)[];
  /**
   * Beschreibender Text
   */
  description: string;
  /**
   * Daten für Zertifikat. Bitte Sebastian für Details fragen
   */
  certificatepayload: {
    details: any[];
    description: string;
    sponsorlabel: string;
    sponsorsubline: string;
  };

  teaser: string;
  price: number;

  mediaitems: MediaItem[];
  language: string;

  classification: number,

  difficulty: number;

  organisation_id: number;
  enddate: string;

  isblended?: boolean;

  organisationlabel: string;

  published: string;
}
