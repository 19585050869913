import { Component, OnInit } from '@angular/core';
import {IdentityService} from "../../utility/services/identity.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'lcms-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {

  constructor(public identityService: IdentityService) { }

  openFrontend(): void {
    window.open ( environment.server.frontend , '_top');
  }

}
