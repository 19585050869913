import { LessonPath } from './lesson-path.interface';
import { User } from './user.interface';
import { Setting } from './setting.interface';
import { Organisation } from './organisation.interface';

export interface UserGroup {
  /**
   * ID
   */
  id: number;
  /**
   * FirmenID zu der diese Nutzergruppe gehört
   */
  organisation: Organisation;
  /**
   * Name
   */
  label: string;
  /**
   * Tags
   */
  tags: string[];
  /**
   * Nutzer in dieser Gruppe
   */
  users?: User[];
  /**
   * Lernpfade dieser Gruppe
   */
  lessonpaths?: LessonPath[];

  setting: Setting;
}
