<div class="top-navigation" *ngIf="this.identityService.user; let user">
  <p class="user-infos">
    <span class="user-name">{{user.firstname + ' ' + user.surname}}</span><br>
    <span class="user-role">{{user.role.label}}</span>
  </p>
  <div class="buttons">
    <button title="Zum Portal" (click)="openFrontend()">
      <mat-icon>home</mat-icon>
    </button>

    <button title="Logout" (click)="identityService.logout()">
      <mat-icon>logout</mat-icon>
    </button>
  </div>
</div>
