import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {TokenStorageService} from '../services/token-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  /**
   * Interceptor der das hinzufügen des Tennant und des Bearer-Tokens zu HTTP-Requests übernimmt.
   * Wird aktuell über das RestServiceModule eingebunden
   */

  constructor(private tokenStorage: TokenStorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    /**
     * Sollte es sich um einen lokalen Request handeln (bspw. eine lokale JSON) überspringe
     */
    if (req.url.startsWith('./')) {
      return next.handle(req);
    }

    let h = req.headers;
    h = h.set('Accept', 'application/json'); // Normalerweise muss dieser Header gesetzt werden da Seb sonst meckert :)

    if (this.tokenStorage.token.value && !req.headers.has('Authorization')) { // Wenn im TokenStorage ein Token liegt, nimm diesen und füge ihn dem Request hinzu
      h = h.set('authorization', 'Bearer ' + this.tokenStorage.token.value);
    }
    // Wenn der Request KEIN Delete und KEIN Upload ist, setze den ContentType auf JSON
    if (req.method !== 'DELETE' && !req.url.includes('multipart')) {
      h = h.set('Content-Type', 'application/json');
    }

    // p.P. Cache deaktivieren für XHR Requests. Ist vermutlich heute nicht mehr nötig,
    // in der Vergangenheit allerdings war dies notwendig für IE
    h = h.set('Cache-Control', 'no-cache, no-store, must-revalidate');
    h = h.set('Vary', '*');
    h = h.set('Pragma', 'no-cache');
    h = h.set('Expires', '0');
    h = h.set('lcms-tenant', environment.server.tenant);

    const modReq: HttpRequest<any> = req.clone({
      headers: h
    });

    return next.handle(modReq);
  }

}
