/**
 * Menüpunkte
 */
export enum Menu {
  COMPANY,
  USER,
  USERGROUPS,
  LESSONS,
  PACKAGES,
  LICENSES,
  GLOSSARY,
  CUSTOMIZATION,
  DOCUMENTS,
  NEWS,
  NEWSLETTER,
  PARTNER,
  STATS,
  LOGS,
  SYSTEMTEXT,
  STATICCONTENT,
  HELP,
  LESSONPATHS,
  ASSIGNMENT,
  AUTHORS,
  PROVIDERS,
  SPONSORS,
  HERO,
  ROOMS,
  BLENDEDLEARNING_LESSONPATH,
  PAGE_NEXT
}
