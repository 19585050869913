export interface Translation {
  /**
   * Sprachkürzel
   */
  language: string;
  /**
   * Text
   */
  message: string;
  /**
   * Flag, ob das Elemement im Webservice gelöscht werden soll.
   */
  delete?: boolean;
}
