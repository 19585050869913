export interface Address {
  /**
   * ID
   */
  id: number;
  /**
   * Straße
   */
  street: string;
  /**
   * Postleitzahl
   */
  zip: string;
  /**
   * Stadt
   */
  city: string;
  /**
   * Stadtteil
   */
  district?: any;
  /**
   * Bundesland
   */
  state?: any;
  /**
   * Land
   */
  country: string;
  /**
   * Adresszusatz
   */
  additional?: any;
  /**
   * GeoCord LAT
   */
  latitude?: any;
  /**
   * GeoCord LON
   */
  longitude?: any;
  /**
   * Rechnungsadresse
   */
  isbilling: boolean;
  /**
   * Rechnungsname
   */
  billingname: string;
  /**
   * Rechnungsabteilung
   */
  billingdivision?: any;
  /**
   * Erzeugt am
   */
  created: Date;
  /**
   * Bearbeitet am
   */
  modified: Date;
}
