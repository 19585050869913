import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, of, Subject, tap } from "rxjs";
import {catchError, take} from "rxjs/operators";
import { LCMSConstants } from '../constants/lcms.constants';
import { LoginState } from '../enums/login-state.enum';
import { SaveData } from '../models/save-data.interface';
import { User } from '../models/user.interface';
import { RESTService } from './rest/rest.service';
import { TokenStorageService } from './token-storage.service';

@Injectable ( {
    providedIn: 'root',
} )
export class IdentityService {

    /**
     * Aktueller Loginstatus des Nutzers
     */
    state: BehaviorSubject<LoginState> = new BehaviorSubject<LoginState> ( LoginState.PENDING );

    /**
     * Rolle des Nutzers im System
     */
    role: BehaviorSubject<number> = new BehaviorSubject<number> ( - 1 );

    /**
     * aktueller Nutzertoken
     */
    token?: string;

    /**
     * aktueller Nutzer
     */
    user?: User;
    restSub!: Subject<any>;

    constructor(
        private rest: RESTService,
        private router: Router,
        private tokenStorage: TokenStorageService,
    ) {
      this.state.subscribe(state => { console.log(state); });
      this.tokenStorage.token.subscribe ( ( next ) => {
        console.log('Next Token', next);
          this.token = next;

          // Sollte es einen Token geben, dieser ungleich '' sein aber KEIN Nutzer eingeloggt, versuche den
          // mit diesem Token einzuloggen
          if ( this.token && this.token.length > 0 && !this.user ) {
              this.login ();
          }
      } );

        // prüfen ob es einen lokal gespeicherten Nutzer gibt
        const stored = sessionStorage.getItem ( 'lcms' );
        if ( stored ) { // Wenn ja lade diesen und setze ihn auf aktiv...
            const d = JSON.parse ( atob ( stored.split ( '' )
                                                .reverse ()
                                                .join ( '' ) )
                .split ( '' )
                .reverse ()
                .join ( '' ) );
            this.handle ( d.user );
        } else { // ...wenn nein, setze den Status auf ausgeloggt
          console.log('SessionStorage not found')
            this.state.next ( LoginState.LOGGEDOUT );
        }
    }

    /**
     * Setze Stati
     * @param user
     * @private
     */
    private handle( user: any ): void {
        this.user = user;
        if ( this.user ) {
            this.role.next ( this.user.role.id );
        }
        this.state.next ( LoginState.LOGGEDIN );
      console.log('Handling user', user);
        // this.router.navigateByUrl('/');

    }

    /**
     * Speichere Nutzerdaten Lokal
     * @param data
     * @private
     */
    private save( data: SaveData ): void {
        const sdata = btoa ( JSON.stringify ( data )
                                 .split ( '' )
                                 .reverse ()
                                 .join ( '' ) )
            .split ( '' )
            .reverse ()
            .join ( '' );
        sessionStorage.setItem ( 'lcms', sdata );
    }

    /**
     * Login mit Nutzername/email und Passwort
     * @param user
     * @param pw
     */
    login( user?: any, pw?: any ): void {
        this.state.next ( LoginState.PENDING );
        this.restSub = this.rest.addRequest ( {
            endpoint: LCMSConstants.LOGIN,
            method  : RESTService.POST,
            payload : user ? {
                username: user,
                password: pw,
            } : {},
        } )

        this.restSub.pipe(
          catchError(err => {
            console.log("Login Error!", err);
            return of(false);
          })
        ).subscribe ( ( response ) => {
              console.log('Login Response; Saving', response);
              if (response) {
                this.save({
                  username: user,
                  password: pw,
                  token: response.token,
                  user: {
                    firstname: response.user.firstname,
                    surname: response.user.surname,
                    role: response.user.role,
                    id: response.user.id,
                  },
                });
                this.tokenStorage.save(response.token, window.location.protocol.indexOf('https') !== -1);
                this.handle(response.user);
              }
            } );
    }

    /**
     * Ausloggen
     */
    logout(): void {
      console.log('Logout called');
        sessionStorage.clear ();
        this.tokenStorage.save ( '', true );
        this.user = undefined;
        this.role.next ( - 1 );
        this.token = undefined;
        this.state.next ( LoginState.LOGGEDOUT );
        this.router.navigateByUrl ( '/login' );
    }
}
