import { Address } from './address.interface';
import { Organisation } from './organisation.interface';
import { Setting } from './setting.interface';
import { Tracking } from './tracking.interface';
import {UserGroup} from "./user.group.interface";

export interface User {
  /**
   * ID des Nutzers
   */
  id: number;
  /**
   * Vorname
   */
  firstname: string;
  /**
   * Nachname
   */
  surname: string;
  /**
   * Emailadresse. Kann für Login verwendet werden
   */
  email: string;
  /**
   * Telefonnummer
   */
  phone?: string;
  /**
   * Mobilfunknummer
   */
  mobile?: any;
  /**
   * Faxnummer
   */
  fax?: string;
  /**
   * Anrede
   */
  salution?: any;
  /**
   * Titel
   */
  title?: string;
  /**
   * Nutzername. Kann für Login verwendet werden
   */
  username: string;
  /**
   * Eingetragen für Newsletter
   */
  isnewsletter: boolean;
  /**
   * Erzeigt am
   */
  created: Date;
  /**
   * Bearbeitet am
   */
  modified: Date;
  /**
   * Erzeugt durch (ID)
   */
  created_by: number;
  /**
   * Bearbeitet durch (ID)
   */
  modified_by: number;
  /**
   * Keine Ahnung
   */
  work_council_id?: any;
  /**
   * ID der Anschrift
   */
  address_id: number;
  /**
   * ID der Einstellungen
   */
  setting_id: number;
  /**
   * ID der Rolle
   */
  role_id: number;
  /**
   * ID der Firma
   */
  organisation_id: number;
  /**
   * Hat den AGB zugestimmt
   */
  isagb: boolean;
  /**
   * Hat dem Datenschutz zugestimmt
   */
  isprivacy: boolean;
  /**
   *
   */
  isrevocation: boolean;
  /**
   * Ist ein Lerner
   */
  islearner: boolean;
  /**
   * Ist versteckt
   */
  isprivate: boolean;

  isActive: string;

  /* Ob dieser Nutzer noch eine offene Einladung hat */
  isinvited?: boolean;
  /**
   * Anonymer Account ohne Email
   */
  isanonymous: boolean;
  /**
   * Passwort zurückgesetzt am
   */
  passwordreseted: Date;
  /**
   * Eingeladen am
   */
  invited: Date;
  /**
   * Letzter Login am
   */
  lastlogin: Date;
  /**
   * Token läuft ab am
   */
  tokenexpires: Date;
  /**
   * Anrede
   */
  salutation: string;
  /**
   * Titel? Vermutlich noch ein Überbleibsel von Thomas
   */
  degree?: any;
  /**
   * Tags, die früher verwendet wurden um Nutzer zu usergruppen zuzuordnen
   */
  tags: any[];
  /**
   * Land
   */
  country_id: number;
  /**
   * Ursprung der Registrierung
   */
  registersource: string;
  /**
   * URsprung des Importes
   */
  importsource?: any;
  /**
   * Jobbezeichnung
   */
  my_position: string;
  /**
   * Branchenbezeichnung
   */
  my_industry: string;
  /**
   * Geburtsdatum
   */
  birthday: Date;
  /**
   * Firmenname
   */
  company: string;
  /**
   * Abteilung
   */
  department: string;
  /**
   * Liste aller Trackings
   */
  trackings: Tracking[];
  /**
   * Firma
   */
  organisation: Organisation;
  /**
   * Adresse
   */
  address: Address;
  /**
   * Zugewiesene Lizenzen
   */
  slots: any[];
  /**
   * Rolle im System
   */
  role: {
    id: number;
    label: string;
  };
  /**
   * Einstellungen
   */
  setting: Setting;
  /**
   * Firmennname
   */
  organisationlabel: string;
  /**
   * Verfügbare Kurse
   */
  lessons: any[];
  /**
   * Verfügbare Lernpfade
   */
  lessonpaths?: any[];
  /*
   * Ist Einladung vorhanden?
   */
  invitation?: boolean;

  /*
   * Benutzergruppen, in denen dieser Nutzer ist
   */
  usergroups?: UserGroup[];

  /*
   * Demonutzerablaufdatum. Wenn ein Datum gesetzt ist, handelt es sich um einen Demonutzer
   */
  expired?: string | null;

  isDemoUser?: string | null
}
