/**
 * Interface für Elemente der ListViewComponent
 */
export interface ListDisplay {
  /**
   * Zeilen die unter/neben dem Icon angezeigt werden
   */
  lines: string[];
  /**
   * Icon
   */
  icon: string;
  /**
   * Datensatz der sich hinter dem Eintrag verbirgt
   */
  data?: any;
  /**
   * Sprache der Kurseinheit
   */
  language?: string;
}
