import { PageEvent } from '@angular/material/paginator/paginator';
import { Sort } from '@angular/material/sort/sort';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Address, LessonPath, Organisation, Setting, Tracking, User } from '../../utility';

export interface Group {
  // ID
  id: number;
  // FirmenID zu der diese Nutzergruppe gehört
  organisation: {
    id: number;
    label: string;
  }
  // Name
  label: string;
  // Tags
  tags: string[];
  // Nutzer in dieser Gruppe
  users: User[];
  // Lernpfade dieser Gruppe
  lessonpaths?: LessonPath[];

  setting: Setting;
}

export interface GroupState extends EntityState<Group> {
  error: boolean;
  loading: boolean;
  total: number;
  page: PageEvent;
  term: string;
  sort: Sort | null;
}

export const groupAdapter: EntityAdapter<Group> = createEntityAdapter<Group> ( {
  selectId: ( group: Group ) => group.id,
} );

export const initialGroupState: GroupState = groupAdapter.getInitialState ( {
  error  : false,
  loading: true,
  total  : 0,
  page   : {
    pageIndex: 0,
    pageSize : 10,
    length   : 0,
  },
  term   : '',
  sort   : {
    active: 'id',
    direction: 'asc'
  },
} );
