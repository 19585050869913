import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {IdentityService} from '../services/identity.service';
import {filter, take} from 'rxjs/operators';
import {LoginState} from '../enums/login-state.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  /**
   * AuthGuard die prüft ob der Nutzer eingeloggt ist. Sollte er NICHT eingeloggt sein, wird er zum Login weitergeleitet
   */

  constructor(private identityService: IdentityService,
              private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return new Observable<boolean | UrlTree>((subscriber) => {
      this.identityService.state
        .pipe(
          untilDestroyed(this),
          filter(val => val !== LoginState.PENDING),
        ).subscribe((loginState) => {
        subscriber.next(loginState === LoginState.LOGGEDIN ? true : this.router.parseUrl('/login'));
        subscriber.complete();
      });
    });
  }
}
