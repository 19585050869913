import { MediaItem } from './media-item.interface';

export interface Provider {
  id: number;
  label: string;
  description: string;
  created_by: number;
  modified_by: number;
  created: Date;
  modified: Date;
  mediaitems: MediaItem[];
}
