export interface MediaItem {
  id: number;
  parent_id: number;
  parenttype: number;
  position: string | null;
  dir: string;
  filename: string;
  size: number;
  filetype: string;
  uri: string;
  created_by: number;
  modified_by: number;
  created: Date;
  modified: Date;
}
