import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, debounceTime, Subject} from "rxjs";
import { tap } from 'rxjs/operators';
import { RestRequest } from './rest-request';

@Injectable({
  providedIn: 'root'
})
export class RESTService {

  get working(): Subject<boolean> {
    return this._working.pipe(debounceTime(200)) as Subject<boolean>;
  }

  constructor(private http: HttpClient) {}

  static readonly POST = 'POST';
  static readonly GET = 'GET';
  static readonly PUT = 'PUT';
  static readonly DELETE = 'DELETE';

  readonly MAXACTIVE = 5; // Anzahl der parallelen Requests;
  readonly error: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  readonly messages: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public version = '0.0.0';
  public live = false;
  private _queue: RestRequest[] = [];
  private _active: RestRequest[] = [];
  private _working: Subject<boolean> = new Subject();

  /**
   * Hinzufügen eines Requests
   * @param request
   */
  addRequest(request: RestRequest): Subject<any> {
    request.subject = new Subject();
    this._queue.push(request);
    this.checkQueue();
    return request.subject;
  }

  /**
   * Request senden/abarbeiten
   * @param index
   * @private
   */
  private sendRequest(index: number): void {
    if (this._active) {
      const active = this._active[index - 1];

      if (active) {
        this.http.request<any>(
          active.method,
          active.endpoint + ((active.endpoint.includes('?')) ? '&' : '?') + 'scbm=' + Math.random(),
          {
            body: active.payload
          }
        )
          .subscribe({
            next: (next) => {
              if (active.subject) {
                active.subject.next((next as any).data);
              }
              this.clear(active);

              // FALLS der Webservice eine Nachricht mitübermittelt, wird diese in das Message-BS geschrieben
              //  um darauf ggf. zu reagieren
              if (next.messages) {
              console.log(next.messages, "message")
                next.messages.forEach((message: any) => {
                  this.messages.next(message);
                });
              }
            },
            error: (error) => {
              console.log(error);
              // FALLS der Webservice einen Fehler mitübermittelt, wird diese in das Message-BS geschrieben
              //  um darauf ggf. zu reagieren
              this.error.next(error);
              try {
                active.subject?.error(error);
              } catch (err) {
                console.warn('NO ERROR MESSAGE ', active.endpoint);
              }

              this.clear(active);

            }});
      }
    }
  }

  /**
   * Aktiven Request aus der Liste entfernen
   * @param item
   * @private
   */
  private clear(item: RestRequest): void {
    this._active?.splice(this._active.indexOf(item), 1);
    this.checkQueue();
  }

  /**
   * Prüfung ob noch weitere Requests in der Warteschlange sind und wenn ja ob die Anzahl der aktiven Requests
   * weiter erlaubt
   * @private
   */
  private checkQueue(): void {

    if (this._active && this._active.length < this.MAXACTIVE) {
      if (this._queue.length > 0) {
        this._working.next(true);
        this.sendRequest(this._active.push(this._queue.shift() as RestRequest));

      } else {
        if (this._active.length === 0) {
          this._working.next(false);
        }

      }

    }
  }

  /**
   * Separate Upload Methode für Multipartuploads.
   * @param url
   * @param data
   */
  multipartUpload(url: string, data: unknown): Subject<any> {

    return this.http.post(
      url,
      data,
      {
        reportProgress: true,
        observe: 'events'
      }
    )
      .pipe(
        tap(event => {
          if (event.type === HttpEventType.Response) {
            const next: any = event.body;
            if (next.messages) {
              next.messages.forEach((message: any) => {
                this.messages.next(message);
              });
            }
          }
        })
      ) as Subject<any>;

  }
}
