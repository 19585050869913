import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './utility/guards/auth.guard';

const routes: Routes = [
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
  },
  {
    path: 'lessons',
    canActivate: [AuthGuard],
    loadChildren: () => import('./lesson/lesson.module').then(m => m.CourseModule),
  },
  {
    path: 'lessonpaths',
    canActivate: [AuthGuard],
    loadChildren: () => import('./lesson-path/lesson-path.module').then(m => m.LessonPathModule),
  },
  {
    path: 'blended-learning-lessonpaths',
    canActivate: [AuthGuard],
    loadChildren: () => import('./blended-learning-lesson-path/blended-learning-lesson-path.module')
      .then(m => m.BlendedLearningLessonPathModule),
  },
  {
    path: 'assignment',
    canActivate: [AuthGuard],
    loadChildren: () => import('./assignment/assignment.module').then(m => m.AssignmentModule),
  },
  {
    path: 'authors',
    canActivate: [AuthGuard],
    loadChildren: () => import('./author/author.module').then(m => m.AuthorModule),
  },
  {
    path: 'providers',
    canActivate: [AuthGuard],
    loadChildren: () => import('./provider/provider.module').then(m => m.ProviderModule),
  },
  {
    path: 'companies',
    canActivate: [AuthGuard],
    loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
  },
  {
    path: 'groups',
    canActivate: [AuthGuard],
    loadChildren: () => import('./group/group.module').then(m => m.GroupModule),
  },
  {
    path: 'glossary',
    canActivate: [AuthGuard],
    loadChildren: () => import('./glossary/glossary.module').then(m => m.GlossaryModule),
  },
  {
    path: 'news',
    canActivate: [AuthGuard],
    loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
  },
  {
    path: 'sponsors',
    canActivate: [AuthGuard],
    loadChildren: () => import('./sponsor/sponsor.module').then(m => m.SponsorModule),
  },
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () => import('./page/page.module').then(m => m.PageModule),
  },
  {
    path: 'pages-next',
    canActivate: [AuthGuard],
    loadChildren: () => import('./page-next/page-next.module').then(m => m.PageNextModule),
  },
  {
    path: 'texts',
    canActivate: [AuthGuard],
    loadChildren: () => import('./system-text/system-text.module').then(m => m.SystemTextModule),
  },
  {
    path: 'statistics',
    canActivate: [AuthGuard],
    loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsModule),
  },
  {
    path: 'hero',
    canActivate: [AuthGuard],
    loadChildren: () => import('./startpage-banner/startpage-banner.module').then(m => m.StartpageBannerModule),
  },
  {
    path: 'locations',
    canActivate: [AuthGuard],
    loadChildren: () => import('./location/location.module').then(m => m.LocationModule),
  },
  {
    path: 'help',
    canActivate: [AuthGuard],
    loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
