export interface BoxFieldDefinition {
  /**
   * ID des Feldes. Kann auch als Key verwendet werden
   */
  id: string;
  /**
   * Typ des Feldes
   */
  type: string;

  /**
   * Bei verschachtelten Typen (e.g. list_complex) enthält objectdie Felddefinitionen
   */
  object?: string[];
}

export interface BoxDefinition {
  /**
   * Beschreibung. Wird in UI angezeigt
   */
  description: string;
  /**
   * Liste aller verfügbaren Felder
   */
  field: BoxFieldDefinition[];
}
