<mat-drawer-container autosize>
  <mat-drawer #drawer mode="side" opened class="menu" [class.hidden]="(identityService.state | async) !== LoginState.LOGGEDIN">
    <mat-nav-list *ngIf="(menuData | async); let menu">
      <a routerLink="/home" class="logo-link">
        <img src="assets/svg/lcms.svg" alt="LCMS Backend Logo">
      </a>

      <a mat-list-item class="navigation-list-link"
         routerLink="/home" routerLinkActive="active">
        <mat-icon matListIcon>dashboard</mat-icon>
        <div matLine>Dashboard</div>
      </a>

      <ng-container *ngFor="let menuCategoryBlock of menu">
        <h3 mat-subheader *ngIf="menuCategoryBlock.categoryItems.length > 0">
          {{menuCategoryBlock.categoryTitle}}
        </h3>

        <a mat-list-item class="navigation-list-link"
           *ngFor="let menuItem of menuCategoryBlock.categoryItems"
           [routerLink]="menuItem.link" routerLinkActive="active">
            <mat-icon matListIcon>{{menuItem.icon}}</mat-icon>
            <div matLine>{{menuItem.label}}</div>
        </a>
      </ng-container>
    </mat-nav-list>
  </mat-drawer>

  <main class="routerbox" [class.inactive]="this.systemService.elementInactive | async">
    <lcms-top-bar></lcms-top-bar>

    <router-outlet></router-outlet>
  </main>
</mat-drawer-container>

<div class="loader" [class.active]="this.restService.working | async"></div>
