import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable( {
  providedIn: 'root'
} )
export class TokenStorageService {
  /**
   * Kleiner Service der das Laden und Speichern des Tokens übernimmt
   */

  constructor() {
    this.load();
  }

  token = new BehaviorSubject<string | undefined>(undefined);

  save(token: string | undefined, secure: boolean): void {
    console.log('Token.Save called');
    this.token.next(token);

    const tmp = document.domain.split ( '.' );
    while ( tmp.length > 2 ) {
      tmp.shift();
    }

    document.cookie = 'lcmstoken=' + token + ';path=/;domain=' + tmp.join ( '.' ) + (secure ? ';secure;' : '');
  }

  load(): void {
    let token;
    const tmp = document.cookie.split ( ';' );
    for ( const s of tmp ) {
      if ( s.indexOf ( 'lcmstoken' ) !== - 1 ) {
        token = s.split ( '=' )
                 .pop ();
      }
    }

    this.token.next(token);
  }
}
