import {createAction, props} from '@ngrx/store';
import {Sort} from '@angular/material/sort/sort';
import {PageEvent} from '@angular/material/paginator/paginator';

import { Company } from './company.state';
import { HttpGroupResponse, Organisation } from '../../utility';
import { User } from '../user-store/user.state';
import { UserActionType } from '../user-store/user.actions';

export enum CompanyActionType {
  CompaniesLoading = '[COMPANIES] Loading Companies',
  CompaniesLoadSuccess = '[COMPANIES] Load Companies Success',
  CompaniesLoadFailure = '[COMPANIES] Load Companies Failure',
  CompaniesToggleActive = '[COMPANIES] Toggle active',
  CompaniesSetPage = '[COMPANIES] Set Page',
  CompaniesSetTerm = '[COMPANIES] Set Search Term',
  CompaniesSetSort = '[COMPANIES] Set Sort',
  CompaniesClear = '[COMPANIES] Clear'
}

export const COMPANIES_LOAD_ACTION = createAction(
  CompanyActionType.CompaniesLoading
);

export const COMPANIES_LOAD_SUCCESS_ACTION = createAction(
  CompanyActionType.CompaniesLoadSuccess,
  props<{
    payload: HttpGroupResponse<Company>
  }> ()
);

export const COMPANIES_LOAD_FAIL_ACTION = createAction(
  CompanyActionType.CompaniesLoadFailure,
  props<{
    error: any
  }> ()
);

export const COMPANIES_TOGGLE_ACTIVE_ACTION = createAction(
    CompanyActionType.CompaniesToggleActive,
    props<{
        company: Company
    }> ()
);

export const COMPANIES_SET_PAGE_ACTION = createAction(
  CompanyActionType.CompaniesSetPage,
  props<{
    page: PageEvent
  }> ()
);

export const COMPANIES_SET_TERM_ACTION = createAction(
  CompanyActionType.CompaniesSetTerm,
  props<{
    terms: string[]
  }> ()
);

export const COMPANIES_SET_SORT_ACTION = createAction(
  CompanyActionType.CompaniesSetSort,
  props<{
    sort: Sort
  }> ()
);

export const COMPANIES_CLEAR_ACTION = createAction(
  CompanyActionType.CompaniesClear
);
