import { Translation } from './translation.interface';

export interface SystemTextItem {
  /**
   * ID
   */
  id?: number;
  /**
   * Schlüssel des feldes, das zusammen mit der section im TextService verwendet wird, um die Texte zu bekommen. e.g. this.txt.read('backend.dashboard_info) würde ein BS liefern das den Inhalt von section = backend und identifier = dashboard_info hat
   */
  identifier: string;
  /**
   * Schlüssel der Sektion, der zusammen mit dem Identifier im TextService verwendet wird, um die Texte zu bekommen. e.g. this.txt.read('backend.dashboard_info) würde ein BS liefern das den Inhalt von section = backend und identifier = dashboard_info hat
   */
  section: string;
  /**
   * Verschiedene Übersetzungen
   */
  translations: Translation[];

  /**
   * geändert?
   */
  changed?: boolean;
}
