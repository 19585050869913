import { MediaItem } from './media-item.interface';

export interface Author {
  id: number;
  firstname: string;
  lastname: string;
  description: string;
  created_by: number;
  modified_by: number;
  created: Date;
  modified: Date;
  mediaitems: MediaItem[];
  /**
   * added so that Delete Dialog won't display undefined
   */
  label: string;
}
