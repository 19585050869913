import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthInterceptor } from '../../interceptors/auth-interceptor';

@NgModule ( {
  imports     : [
    CommonModule,
    HttpClientModule
  ],
  declarations: [],
  providers   : [
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi   : true
    },
    HttpClient
  ]
} )
export class RestServiceModule {
}
