import {BaseService} from "./base-service";
import {RESTService} from "./rest/rest.service";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable, of, tap} from "rxjs";
import {Injectable} from "@angular/core";
import {StaticHelp} from "../models/static-help.interface";
import {LCMSConstants} from "../constants/lcms.constants";
import {HttpGroupResponse} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class StaticHelpService implements BaseService {

  /**
   * Alle Hilfen im System
   */
  readonly staticHelps: BehaviorSubject<StaticHelp[]> = new BehaviorSubject<StaticHelp[]>([]);

  /**
   * Die aktuell ausgewählte Hilfe
   */
  readonly staticHelp: BehaviorSubject<StaticHelp | undefined> = new BehaviorSubject<StaticHelp | undefined>(undefined);


  constructor(
    private rest: RESTService,
    private http: HttpClient
  ) {
  }

  addEntity(label: string): Observable<any> {
    return this.rest.addRequest({
      method: RESTService.POST,
      endpoint: LCMSConstants.HELP,
      payload: {
        label: label
      }
    });
  }

  deleteEntity(entity: any): Observable<any> {
    return this.rest.addRequest({
      method: RESTService.DELETE,
      endpoint: LCMSConstants.HELP + "/" + entity.id
    })
  }

  fetchStaticHelps(): Observable<StaticHelp[]> {
    return this.rest.addRequest({
      method: RESTService.GET,
      endpoint: LCMSConstants.HELP
    })
      .pipe(
        tap(staticHelps => this.staticHelps.next(staticHelps))
      ) as Observable<StaticHelp[]>;
  }

  fetchStaticHelpsState(filter?: string): Observable<HttpGroupResponse<StaticHelp>> {
    let endpoint = LCMSConstants.HELP;

    if (filter) {
      endpoint += filter;
    }

    return this.http.get<HttpGroupResponse<StaticHelp>>(endpoint);
  }

  loadStaticHelpDetails(id: string | number): Observable<StaticHelp> {
    return this.rest.addRequest({
      method: RESTService.GET,
      endpoint: LCMSConstants.HELP + '/' + id,
    })
      .pipe(
        tap(staticHelp => this.staticHelp.next(staticHelp)),
      ) as Observable<StaticHelp>;
  }

  saveStaticHelpDetails(staticHelp: StaticHelp): Observable<StaticHelp> {
    return this.rest.addRequest({
      method: RESTService.PUT,
      endpoint: LCMSConstants.HELP + '/' + staticHelp.id,
      payload: staticHelp
    }).pipe() as Observable<StaticHelp>;
  }
}
