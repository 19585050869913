import { PageEvent } from '@angular/material/paginator/paginator';
import { Sort } from '@angular/material/sort/sort';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Address, Organisation, Setting, Tracking } from '../../utility';
import { User } from '../user-store/user.state';

export interface Company {
   // ID
  id: number;
   // Name der Firma
  label: string;
   // Rechnungsname
  billinglabel?: any;
   // Namenszusatz
  additional: string;
   // Abteilung
  department: string;
   // UStID
  ustid: string;
   // Notiz
  infotext: string;
   // Erzeugt am
  created: Date;
   // Bearbeitet am
  modified: Date;
   // Erzeugt von
  created_by: number;
   // Bearbeitet von
  modified_by: number;
   // ist Niederlassung von
  parent_id?: any;
   // Einstellungen
  setting: Setting;
   // Einstellungs ID
  setting_id: number;
   // Hauptniederlassung oder Zweigstelle
  level: number;
  lft: number;
  rght: number;
   // Hat erweiterte Statistiken
  isstatistics: boolean;
   // ID des Firmenadmin
  user_id?: any;

  /* Mitarbeiter der Firma */
  users: User[];
   // Importquelle
  importsource?: any;
   // Customization Data. Farben, Logo etc.
  customization?: any;
   // Adressen.
  addresses: Address[];
   // Hinterlegte Dokumente
  documents: any[];
   // Adminname
  adminlabel: string;
   // Anschrift
  address: Address;
   // Rechnungsanschrift
  billingaddress: Address;
}

export interface CompanyState extends EntityState<Company> {
  error: boolean;
  loading: boolean;
  total: number;
  page: PageEvent;
  terms: string[];
  sort: Sort | null;
}

export const companyAdapter: EntityAdapter<Company> = createEntityAdapter<Company> ( {
  selectId: ( company: Company ) => company.id,
} );

export const initialCompanyState: CompanyState = companyAdapter.getInitialState ( {
  error  : false,
  loading: true,
  total  : 0,
  page   : {
    pageIndex: 0,
    pageSize : 10,
    length   : 0,
  },
  terms   : [],
  sort   : {
    active: 'id',
    direction: 'asc'
  },
} );
