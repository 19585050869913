export interface Setting {

  /**
   * Aktiv ja/nein. Im Regelfall werden Elemente bei denen dies auf true gesetzt nicht im FE ausgeliefert
   */
  isactive: boolean;
  /**
   * Sichbar ja/nein
   */
  isvisible?: boolean;
  /**
   * Gelöscht ja/nein
   */
  isdeleted?: boolean;
  /**
   * Testelement ja/nein
   */
  istest?: boolean;
  /**
   * Bearbeitsbar ja/nein
   */
  iseditable?: boolean;
  /**
   * Löschbar ja/nein. Bei manchen element soll die möglich sie zu löschen vom System aus verhindert werden. e.g. die DynamicPage bei den Static pages
   */
  isdeletable?: boolean;
}
