import {createAction, props} from '@ngrx/store';
import {Sort} from '@angular/material/sort/sort';
import {PageEvent} from '@angular/material/paginator/paginator';

import {Group} from './group.state';
import { HttpGroupResponse } from '../../utility';

export enum GroupActionType {
  GroupsLoading = '[GROUPS] Loading Groups',
  GroupsLoadSuccess = '[GROUPS] Load Groups Success',
  GroupsLoadFailure = '[GROUPS] Load Groups Failure',
  GroupsSetPage = '[GROUPS] Set Page',
  GroupsSetTerm = '[GROUPS] Set Search Term',
  GroupsSetSort = '[GROUPS] Set Sort',
  GroupsClear = '[GROUPS] Clear'
}

export const GROUPS_LOAD_ACTION = createAction(
  GroupActionType.GroupsLoading
);

export const GROUPS_LOAD_SUCCESS_ACTION = createAction(
  GroupActionType.GroupsLoadSuccess,
  props<{
    payload: HttpGroupResponse<Group>
  }> ()
);

export const GROUPS_LOAD_FAIL_ACTION = createAction(
  GroupActionType.GroupsLoadFailure,
  props<{
    error: any
  }> ()
);

export const GROUPS_SET_PAGE_ACTION = createAction(
  GroupActionType.GroupsSetPage,
  props<{
    page: PageEvent
  }> ()
);

export const GROUPS_SET_TERM_ACTION = createAction(
  GroupActionType.GroupsSetTerm,
  props<{
    term: string
  }> ()
);


export const GROUPS_SET_SORT_ACTION = createAction(
  GroupActionType.GroupsSetSort,
  props<{
    sort: Sort
  }> ()
);

export const GROUPS_CLEAR_ACTION = createAction(
  GroupActionType.GroupsClear
);
