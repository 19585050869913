import { Lesson } from './lesson.interface';
import {ChapterLesson} from "./chapter-lesson.interface";

export interface LessonPathChapter {
  /**
   * ID
   */
  id: number;
  /**
   * Klassifizierung des Kapitels (Basic/Demo/Pro)
   */
  classification: string;
  /**
   * Beschreibender Text
   */
  description: string;
  /**
   * Schwierigkeit
   */
  difficulty: number;
  /**
   * Dauer
   */
  duration: number;
  /**
   * Kapitel hat eigenes Zertifikat?
   */
  has_certificate: boolean;
  /**
   * Kapitel aktiv?
   */
  isactive: boolean;
  /**
   * Lernpfad zu dem dieses Kapitel gehört
   */
  lessonpath_id: number;
  /**
   * Punktzahl
   */
  score: number;
  /**
   * Ablauftyp. "free" | "linear" aktuell
   */
  sequence: string;
  /**
   * Kapitelname
   */
  label: string;
  /**
   * Slug
   */
  slug: string;
  /**
   * Kurse in diesem Kapitel
   */
  lessons: Lesson[];

  /**
   * _joinData is a cakephp special field, which is used to save additional data on associated relations.
   * In this case it is a table called chapters_lessons.
   *
   */
  _joinData? : ChapterLesson;
}
