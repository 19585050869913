export interface StaticHelp {
  id: number;
  label: string;
  payload: {
    entries: StaticHelpEntry[]
    title: string,
    uname: string,
    icon: string
  };
}

export interface StaticHelpEntry {
  id: number,
  iscommon: boolean,
  answer: string,
  question: string
}
