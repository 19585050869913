import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of, Subject} from "rxjs";
import {HttpGroupResponse, HttpSingleResponse} from "./user.service";
import {LCMSConstants} from "../constants/lcms.constants";
import {RESTService} from "./rest/rest.service";
import {Location} from "../../_root-store/location-store/location.state";
import {Room, RoomBase} from "../models/room.interface";
import {BaseService} from "./base-service";

@Injectable({
  providedIn: 'root'
})
export class LocationService implements BaseService {

  constructor(private http: HttpClient) {}

  deleteEntity( entity: any ) {
    return this.deleteLocation(entity)
  }

  addEntity( entity: any ) {
    return this.addLocation(entity.textinput)
  }

  fetchLocations(searchRequests?: string): Observable<HttpGroupResponse<Location>> {
    const urlParams = searchRequests ?? '';

    return this.http.get<HttpGroupResponse<Location>>(LCMSConstants.LOCATIONS + urlParams);
  }

  loadLocationDetails(id: number | string): Observable<HttpSingleResponse<Location>> {
    return this.http.get<HttpSingleResponse<Location>>(LCMSConstants.LOCATIONS + '/' + id);
  }

  loadLocationRooms(id: number | string): Observable<HttpGroupResponse<Room>> {
    return this.http.get<HttpGroupResponse<Room>>(LCMSConstants.ROOMS + '?location_id=' + id);
  }

  saveLocationDetails(location: Location) {
    return this.http.put(LCMSConstants.LOCATIONS + '/' + location.id, location);
  }

  saveRoomDetails(room: Room) {
    return this.http.put(LCMSConstants.ROOMS + '/' + room.id, room);
  }

  addLocation( location: Location ) {
    return this.http.post(LCMSConstants.LOCATIONS, {...location, uname: encodeURI(location.label.toLowerCase())});
  }

  addRoom( room: RoomBase ) {
    return this.http.post(LCMSConstants.ROOMS, room);
  }

  deleteLocation(location: Location) {
    return this.http.delete(LCMSConstants.LOCATIONS + '/' + location.id);
  }

  deleteRoom(room: Room) {
    return this.http.delete(LCMSConstants.ROOMS + '/' + room.id);
  }
}
