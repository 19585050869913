import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LCMSConstants } from '../constants/lcms.constants';
import { UserGroup } from '../models/user.group.interface';
import { BaseService } from './base-service';
import { RESTService } from './rest/rest.service';
import { HttpGroupResponse, HttpSingleResponse } from './user.service';
import { Company } from '../../_root-store/company-store/company.state';
import { Group } from '../../_root-store/group-store/group.state';
import { HttpClient } from '@angular/common/http';

@UntilDestroy ( { checkProperties: true } )
@Injectable ( {
    providedIn: 'root',
} )
export class UserGroupService implements BaseService {

    readonly userGroups: BehaviorSubject<UserGroup[]> = new BehaviorSubject<UserGroup[]> ( [] );

    constructor( private rest: RESTService,
                 private http: HttpClient ) {
    }

    addEntity( entity: any ) {
        return this.rest.addRequest ( {
            method  : RESTService.POST,
            endpoint: LCMSConstants.GROUPS,
            payload : {
                label          : entity?.textinput,
                organisation_id: entity.selection,
                tags           : [entity.selection + '_' + entity?.textinput],
            },
        } ) as Observable<any>;
    }

    deleteEntity( entity: any ) {
        return this.rest.addRequest ( {
            method  : RESTService.DELETE,
            endpoint: LCMSConstants.GROUPS + '/' + entity.id,
        } ) as Observable<any>;
    }

    fetchGroups(): Observable<UserGroup[]> {
        return this.rest.addRequest ( {
            method  : RESTService.GET,
            endpoint: LCMSConstants.GROUPS,
        } )
                   .pipe (
                       tap ( groups => this.userGroups.next ( groups ) ),
                   );
    }

  /**
   * Lade Usergruppen
   */
  fetchGroupsState(filter?: any): Observable<HttpGroupResponse<Group>> {
    let endpoint = LCMSConstants.GROUPS;

    if (filter) {
      endpoint += filter;
    }

    return this.http.get<HttpGroupResponse<Group>>(endpoint);
  }

  /**
   * Lade einzelne Usergruppe
   */
  fetchSingleGroup(id: number | string): Observable<HttpSingleResponse<Group>> {
    let endpoint = LCMSConstants.GROUPS + '/' + id;

    return this.http.get<HttpSingleResponse<Group>>(endpoint);
  }

  /*
   * Füge eine neue Usergruppe hinzu
   */
  addGroup( label: string, company: number ): Observable<any> {
    const body = {
      label,
      organisation_id: company,
      tags           : [company + '_' + label],
    };

    return this.http.post(LCMSConstants.GROUPS, body);
  }

  /**
   * Speichere Gruppendetails
   * @param group
   */
  saveGroupDetails( group: any ): Observable<UserGroup> {
    return this.rest.addRequest ( {
      method  : RESTService.PUT,
      endpoint: LCMSConstants.GROUPS + '/' + group.id,
      payload : group,
    } )
      .pipe (
      ) as Observable<UserGroup>;
  }
}
