import { Directive } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { SystemService } from '../services/system.service';

@Directive ()
export abstract class AbstractDialogComponent {

  protected constructor(
    protected system: SystemService,
    protected dialog: MatDialogRef<any>
  ) {

    this.system.dialogOpen.next(true);

    this.dialog.afterClosed()
      .pipe(
        take(1)
      ).subscribe((close) => {
      this.system.dialogOpen.next(false);
    });
  }
}
