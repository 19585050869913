import {Injectable} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, map, tap} from "rxjs/operators";
import {LCMSConstants} from '../constants/lcms.constants';
import {LessonBundle} from '../models/lesson-bundle.interface';
import {LessonPath} from '../models/lesson-path.interface';
import {Lesson} from '../models/lesson.interface';
import {Tag} from '../models/tag.interface';
import {BaseService} from './base-service';
import {RESTService} from './rest/rest.service';
import {HttpClient} from "@angular/common/http";
import {HttpBaseResponse, HttpGroupResponse, HttpSingleResponse} from "./user.service";
import {LessonCategory} from "../models/lesson-category.interface";
import {Group} from "../../_root-store/group-store/group.state";
import {Company} from "../../_root-store/company-store/company.state";
import {LessonPathState} from "../../_root-store/lesson-path-store/lesson-path.state";

@UntilDestroy({checkProperties: true})
@Injectable({
  providedIn: 'root',
})
export class LessonService implements BaseService {
  /**
   * Alle Kurse im System
   */
  readonly lessons: BehaviorSubject<Lesson[]> = new BehaviorSubject<Lesson[]>([]);
  /**
   * Der aktuell ausgewählte Kurs
   */
  readonly lesson: BehaviorSubject<Lesson | undefined> = new BehaviorSubject<Lesson | undefined>(undefined);

  /**
   * Alle Kurspakete im System
   */
  readonly lessonBundles: BehaviorSubject<LessonBundle[]> = new BehaviorSubject<LessonBundle[]>([]);
  /**
   * das aktuell ausgewählte Paket
   */
  readonly lessonBundle: BehaviorSubject<LessonBundle | undefined> = new BehaviorSubject<LessonBundle | undefined>(undefined);

  /**
   * Alle Lernpfade im System
   */
  readonly lessonPaths: BehaviorSubject<LessonPath[]> = new BehaviorSubject<LessonPath[]>([]);
  /**
   * der Aktuell ausgewählte Lernpfad
   */
  readonly lessonPath: BehaviorSubject<LessonPath | undefined> = new BehaviorSubject<LessonPath | undefined>(undefined);

  readonly tags: BehaviorSubject<Tag[]> = new BehaviorSubject<Tag[]>([]);

  constructor(
    private rest: RESTService,
    private http: HttpClient
  ) {

  }

  deleteEntity(entity: any) {
    return this.deleteLesson(entity);
  }

  addEntity($event: any) {
    // No need of add because of import
    return of(undefined);
  }

  /**
   * Abrufen aller Kurse
   */
  fetchLessons(): Observable<Lesson[]> {
    return this.rest.addRequest({
      method: RESTService.GET,
      endpoint: LCMSConstants.LESSONS,
    })
      .pipe(
        map((lessons) => {
          lessons.forEach((lesson: Lesson) => {
            if (lesson.published) {
              lesson.toCome = new Date(lesson.published) > new Date();
            }
          });

          return lessons;
        }),
        tap(lessons => this.lessons.next(lessons)),
      ) as Observable<Lesson[]>;
  }

  fetchLessonsState(filter?: string): Observable<HttpGroupResponse<Lesson>> {
    let endpoint = LCMSConstants.LESSONS;

    if (filter) {
      endpoint += filter;
    }

    return this.http.get<HttpGroupResponse<Lesson>>(endpoint);
  }

  fetchLessonOrdering(): Observable<HttpSingleResponse<LessonCategory>> {
    return this.http.get<HttpSingleResponse<LessonCategory>>(LCMSConstants.LESSON_CATEGORIES + '/1');
  }

  saveLessonOrdering(lessonCategory: LessonCategory): Observable<any> {
    return this.http.put(LCMSConstants.LESSON_CATEGORIES + '/1', lessonCategory);
  }

  /**
   * Kurs aktivieren/deaktivieren
   * @param state
   * @param lesson
   */
  setLessonActive(state: boolean, lesson: Lesson): void {
    lesson.setting.isactive = state;
    if (lesson.hasOwnProperty('isActive')) {
      lesson.isActive = state ? 'aktiv' : 'gesperrt';
    }

    this.rest.addRequest({
      method: RESTService.PUT,
      endpoint: LCMSConstants.LESSONS + '/' + lesson.id,
      payload: lesson,
    })
      .subscribe((response) => {
        if (this.lesson.value?.id === lesson.id) {
          this.loadLessonDetails(lesson.id)
            .subscribe(() => {
            });
        }
      });
  }

  setLessonActiveState(lesson: Lesson) {
    const tmpLesson = {...lesson, setting: {...lesson.setting, isactive: !lesson.setting.isactive}};

    return this.http.put(LCMSConstants.LESSONS + '/' + lesson.id, tmpLesson);
  }

  /**
   * Kurs als empfohlen markieren/markierung entfernen
   * @param state
   * @param lesson
   */
  setLessonRecommended(state: boolean, lesson: Lesson): void {
    lesson.isrecommended = state;
    if (lesson.hasOwnProperty('isRecommendedFormatted')) {
      lesson.isRecommendedFormatted = lesson.isrecommended ? 'ja' : 'nein';
    }

    this.rest.addRequest({
      method: RESTService.PUT,
      endpoint: LCMSConstants.LESSONS + '/' + lesson.id,
      payload: lesson,
    })
      .subscribe((response) => {
        if (this.lesson.value?.id === lesson.id) {
          this.loadLessonDetails(lesson.id)
            .subscribe(() => {
            });
        }
      });
  }

  /**
   * Kursdetails anhand der ID des Kurses laden
   * @param id
   */
  loadLessonDetails(id: string | number): Observable<Lesson> {
    return this.rest.addRequest({
      method: RESTService.GET,
      endpoint: LCMSConstants.LESSONS + '/' + id,
    })
      .pipe(
        tap(lesson => this.lesson.next(lesson)),
      ) as Observable<Lesson>;
  }

  /**
   * Kursdetails speichern
   * @param lesson
   */
  saveLessonDetails(lesson: Lesson): Observable<any> {
    return this.rest.addRequest({
      method: RESTService.PUT,
      endpoint: LCMSConstants.LESSONS + '/' + lesson.id,
      payload: lesson,
    })
      .pipe(
      ) as Observable<any>;
  }

  /**
   * Kurs löschen
   * @param lesson
   */
  deleteLesson(lesson: Lesson): Observable<any> {
    return this.rest.addRequest({
      method: RESTService.DELETE,
      endpoint: LCMSConstants.LESSONS + '/' + lesson.id,
    })
      .pipe(
      ) as Observable<any>;
  }

  /**
   * Kurspakete laden
   */
  fetchLessonBundles(): Observable<LessonBundle[]> {
    return this.rest.addRequest({
      method: RESTService.GET,
      endpoint: LCMSConstants.LESSONBUNDLES,
    })
      .pipe(
        tap(lessonBundles => this.lessonBundles.next(lessonBundles)),
      ) as Observable<LessonBundle[]>;
  }

  /**
   * Kurspaket aktivieren/deaktivieren
   * @param state
   * @param lessonBundle
   */
  setLessonBundleActive(state: boolean, lessonBundle: LessonBundle): void {
    lessonBundle.setting.isactive = state;
    this.rest.addRequest({
      method: RESTService.PUT,
      endpoint: LCMSConstants.LESSONBUNDLES + '/' + lessonBundle.id,
      payload: lessonBundle,
    })
      .subscribe((response) => {
        if (this.lessonBundle.value?.id && this.lessonBundle.value.id === lessonBundle.id) {
          this.loadLessonBundleDetails(lessonBundle.id)
            .subscribe(() => {
            });
        }
      });
  }

  /**
   * Kurspaket als empfohlen markieren / Markierung aufheben
   * @param state
   * @param lessonBundle
   */
  setLessonBundleRecommended(state: boolean, lessonBundle: LessonBundle): void {
    lessonBundle.isrecommended = state;
    this.rest.addRequest({
      method: RESTService.PUT,
      endpoint: LCMSConstants.LESSONBUNDLES + '/' + lessonBundle.id,
      payload: lessonBundle,
    })
      .subscribe((response) => {
        if (this.lessonBundle.value?.id && this.lessonBundle.value.id === lessonBundle.id) {
          this.loadLessonBundleDetails(lessonBundle.id)
            .subscribe(() => {
            });
        }
      });
  }

  /**
   * Details eines Kurspaketes anhand seiner ID laden
   * @param id
   */
  loadLessonBundleDetails(id: number): Observable<LessonBundle> {
    return this.rest.addRequest({
      method: RESTService.GET,
      endpoint: LCMSConstants.LESSONBUNDLES + '/' + id,
    })
      .pipe(
        tap(bundle => this.lessonBundle.next(bundle)),
      ) as Observable<LessonBundle>;
  }

  /**
   * Kurspaketdetails speichern
   * @param data
   */
  saveLessonBundleDetails(data: any): Observable<any> {
    return this.rest.addRequest({
      method: RESTService.PUT,
      endpoint: LCMSConstants.LESSONBUNDLES + '/' + data.id,
      payload: data,
    }) as Observable<any>;
  }

  /**
   * Kurspaket hinzufügen
   * @param label
   */
  addLessonBundle(label: string): Observable<any> {
    const bundle: LessonBundle = {
      label,
      setting: {
        isactive: false,
      },
    };

    return this.rest.addRequest({
      method: RESTService.POST,
      endpoint: LCMSConstants.LESSONBUNDLES,
      payload: bundle,
    }) as Observable<any>;

  }

  /**
   * Kurspaket löschen
   * @param lessonBundle
   */
  deleteLessonBundle(lessonBundle: LessonBundle): Observable<any> {
    return this.rest.addRequest({
      method: RESTService.DELETE,
      endpoint: LCMSConstants.LESSONBUNDLES + '/' + lessonBundle.id,
    }) as Observable<any>;
  }

  /**
   * Lade alle Lernpfade im System
   */
  fetchLessonPaths(): Observable<LessonPath[]> {
    return this.rest.addRequest({
      method: RESTService.GET,
      endpoint: LCMSConstants.LESSONPATHS,
    })
      .pipe(
        tap(lessonPaths => this.lessonPaths.next(lessonPaths)),
      ) as Observable<LessonPath[]>;
  }

  fetchLessonPathsState(filter?: string): Observable<HttpGroupResponse<LessonPath>> {
    let endpoint = LCMSConstants.LESSONPATHS;

    if (filter) {
      endpoint += filter;
    }

    return this.http.get<HttpGroupResponse<LessonPath>>(endpoint);
  }


  /**
   * Lernpfad aktivieren/deaktivieren
   * @param state
   * @param lessonPath
   */
  setLessonPathActive(state: boolean, lessonPath: LessonPath): void {
    lessonPath.isactive = state;
    if (lessonPath.hasOwnProperty('isActive')) {
      lessonPath.isActive = state ? 'aktiv' : 'gesperrt';
    }

    this.rest.addRequest({
      method: RESTService.PUT,
      endpoint: LCMSConstants.LESSONPATHS + '/' + lessonPath.id,
      payload: lessonPath,
    })
      .subscribe((response) => {
        if (this.lessonPath.value?.id && this.lessonPath.value.id === lessonPath.id) {
          this.loadLessonPathDetails(lessonPath.id)
            .subscribe(() => {
            });
        }
      });
  }

  setLessonPathActiveState(lessonPath: LessonPath) {
    const tmpLessonPath = {...lessonPath, isactive: !lessonPath.isactive};

    return this.http.put(LCMSConstants.LESSONPATHS + '/' + lessonPath.id, tmpLessonPath);
  }

  /**
   * Lernpfaddetails anhand der ID laden
   * @param id
   */
  loadLessonPathDetails(id: number | string): Observable<LessonPath> {
    return this.rest.addRequest({
      method: RESTService.GET,
      endpoint: LCMSConstants.LESSONPATHS + '/' + id,
    })
      .pipe(
        tap(path => this.lessonPath.next(path)),
      ) as Observable<LessonPath>;
  }

  /**
   * Lernpfad als empfohlen markieren / Markierug aufheben
   * @param state
   * @param path
   */
  setLessonPathRecommended(state: boolean, path: LessonPath): void {
    path.isrecommended = state;
    if (path.hasOwnProperty('isRecommendedFormatted')) {
      path.isRecommendedFormatted = path.isrecommended ? 'ja' : 'nein';
    }

    this.rest.addRequest({
      method: RESTService.PUT,
      endpoint: LCMSConstants.LESSONPATHS + '/' + path.id,
      payload: path,
    })
      .subscribe((response) => {
        if (path.id && this.lessonPath.value?.id === path.id) {
          this.loadLessonPathDetails(path.id)
            .subscribe(() => {
            });
        }
      });
  }

  setLessonPathRecommendState(path: LessonPath) {
    const tmpLessonPath: LessonPath = {...path, isrecommended: !path.isrecommended};

    if (path.hasOwnProperty('isRecommendedFormatted')) {
      tmpLessonPath.isRecommendedFormatted = tmpLessonPath.isrecommended ? 'ja' : 'nein';
    }

    return this.http.put(LCMSConstants.LESSONPATHS + '/' + path.id, tmpLessonPath);
  }

  /**
   * Lernpfad als privat markieren/markierung aufheben. Private Lernpfade erscheinen nicht in der
   * Allgemeinen Liste sondern sind nur für Nutzer, die dafür freigeschaltet sind sichtbar
   * @param state
   * @param path
   */
  setLessonPathPrivate(state: boolean, path: LessonPath): void {
    path.isprivate = state;
    if (path.hasOwnProperty('isVisible')) {
      path.isVisible = path.isprivate ? 'privat' : 'öffentlich';
    }

    this.rest.addRequest({
      method: RESTService.PUT,
      endpoint: LCMSConstants.LESSONPATHS + '/' + path.id,
      payload: path,
    })
      .subscribe((response) => {

        // sollte der aktuell ausgewähle Lernpfad der Pfad sein der hier bearbeitet wurde, lade dessen Daten nochmal
        // Sicher ist sicher
        if (path.id && this.lessonPath.value?.id === path.id) {
          this.loadLessonPathDetails(path.id)
            .subscribe(() => {
            });
        }
      });
  }

  setLessonPathPrivateState(path: LessonPath) {
    const tmpLessonPath: LessonPath = {...path, isprivate: !path.isprivate};

    if (path.hasOwnProperty('isVisible')) {
      tmpLessonPath.isVisible = tmpLessonPath.isprivate ? 'privat' : 'öffentlich';
    }

    return this.http.put(LCMSConstants.LESSONPATHS + '/' + path.id, tmpLessonPath);
  }

  /**
   * Lernpfad löschen
   * @param path
   */
  deleteLessonPath(path: LessonPath): Observable<any> {
    return this.rest.addRequest({
      method: RESTService.DELETE,
      endpoint: LCMSConstants.LESSONPATHS + '/' + path.id,
    }) as Observable<any>;
  }

  /**
   * Neuen Lernpfad anlegen
   * @param label
   */
  addLessonPath(label: string): Observable<any> {
    const path: LessonPath = {
      label,
      isactive: false,
      description: 'Beschreibung des Lernpfades',
    } as LessonPath;

    return this.rest.addRequest({
      method: RESTService.POST,
      endpoint: LCMSConstants.LESSONPATHS,
      payload: path,
    }) as Observable<any>;

  }

  /**
   * Lernpfad speochern
   * @param data
   */
  saveLessonPathDetails(data: LessonPath): Observable<any> {
    return this.rest.addRequest({
      method: RESTService.PUT,
      endpoint: LCMSConstants.LESSONPATHS + '/' + data.id,
      payload: data,
    }) as Observable<any>;
  }

  /**
   * Neuen Promo-Kurs anlegen. Ein Promotion Kurs ist im Grunde ein "Leerer Kurs" der im Frontend
   * angekündigt werden soll, für den es allerdings noch keinen tatsächlichen Kurs gibt
   * @param data
   */
  addPromoLesson(data: any): Observable<any> {
    if (data) {

      const y = new Date().getFullYear() + 5;
      const dummy = new Date();
      dummy.setFullYear(y);
      return this.rest.addRequest({
        method: RESTService.POST,
        endpoint: LCMSConstants.LESSONS,
        payload: {
          label: data.textinput,
          lang: data.language,
          type: data.type,
          published: dummy,
        },
      }) as Observable<any>;
    }
    // ToDo you can only click upload button again, after clicking another button or reload. can't quite follow
    // the original authors intenion here, so didn't wanted to waste more time on that
    return new Observable(subscriber => {
      subscriber.complete();
    });
  }

  fetchTags(): Observable<any> {
    return this.rest.addRequest({
      method: RESTService.GET,
      endpoint: LCMSConstants.TAGS,
    })
      .pipe(
        tap(tags => this.tags.next(tags)),
      ) as Observable<Tag[]>;
  }

  loadTags(): Observable<HttpGroupResponse<Tag>> {
    return this.http.get<HttpGroupResponse<Tag>>(LCMSConstants.TAGS);
  }


  getListOfTags(): string[] {
    const tags = this.tags.value.map((tag) => tag.label);
    return tags;
  }
}
