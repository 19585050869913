<mat-dialog-content>
    <div [innerHTML]="this.data.txt" cdkFocusInitial></div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button color="warn" (click)="click(false)">
        Abbrechen
        <mat-icon>close</mat-icon>
    </button>
    <div class="spacer"></div>
    <button mat-raised-button color="primary" (click)="click(true)">
        Bestätigen
        <mat-icon>done</mat-icon>
    </button>
</mat-dialog-actions>
