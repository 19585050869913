import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { of, tap } from 'rxjs';
import {catchError, map, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {
  GROUPS_LOAD_ACTION,
  GROUPS_LOAD_FAIL_ACTION,
  GROUPS_LOAD_SUCCESS_ACTION,
  GroupActionType
} from './group.actions';
import {Group} from './group.state';
import { selectGroupSearchRequest } from './group.selector';
import { AppState } from '../app.state';
import { UserGroupService } from '../../utility/services/user-group.service';

@Injectable({
  providedIn: 'root'
})
export class GroupEffects {
  public loadGroups$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(GroupActionType.GroupsLoading),
        switchMap(() =>
          this.store.pipe(
            select(selectGroupSearchRequest),
            take(1),
            switchMap(searchRequest => {
                return this.groupService.fetchGroupsState(searchRequest).pipe(
                  map((response) => GROUPS_LOAD_SUCCESS_ACTION({
                    payload: response
                  })),
                );
              }
            )
          )
        ),
        catchError((error) => of(GROUPS_LOAD_FAIL_ACTION(error)))
      );
  });

  public setPage$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          GroupActionType.GroupsSetPage,
          GroupActionType.GroupsSetTerm,
          GroupActionType.GroupsSetSort,
        ),
        map(action => GROUPS_LOAD_ACTION())
      );
  });

  constructor(private groupService: UserGroupService,
              private actions$: Actions,
              private store: Store<AppState>) {
  }
}
