import {initialGroupState, groupAdapter, GroupState} from './group.state';
import {GroupActionType} from './group.actions';

export function groupReducer(state = initialGroupState, action: any): GroupState {
  switch (action.type) {

    case GroupActionType.GroupsLoading: {
      return {
        ...state,
        loading: true
      };
    }

    case GroupActionType.GroupsLoadSuccess: {
      return groupAdapter.setAll(action.payload.data, {
        ...state,
        error: false,
        loading: false,
        total: action.payload.pagination.count,
      });
    }

    case GroupActionType.GroupsLoadFailure: {
      return groupAdapter.removeAll({
        ...state,
        error: true,
        loading: false,
        total: 0
      });
    }

    case GroupActionType.GroupsClear: {
      return groupAdapter.removeAll({
        ...state,
        error  : false,
        loading: false,
        total  : 0,
        page   : {
          pageIndex: 0,
          pageSize : 25,
          length   : 0,
        },
        term   : '',
        sort   : null
      });
    }

    case GroupActionType.GroupsSetPage: {
      return {
        ...state,
        page: action.page
      };
    }

    case GroupActionType.GroupsSetTerm: {
      return {
        ...state,
        term: action.term,
        page: {
          ...state.page,
          pageIndex: 0,
        }
      };
    }

    case GroupActionType.GroupsSetSort: {
      return {
        ...state,
        page   : {
          ...state.page,
          pageIndex: 0,
        },
        sort: action.sort
      };
    }

    default:
      return state;
  }
}
