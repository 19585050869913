import { Injectable } from '@angular/core';
import { OrganisationTableInput } from '../../reusable-components/table-view/interfaces/organisation-table.input';
import { Organisation } from '../models/organisation.interface';

@Injectable({
  providedIn: 'root'
})
export class OrganisationMapperService {

     mapToOrganisationTableInput( organisation: Organisation ): OrganisationTableInput {
        return {
            ...organisation,
            isActive:  organisation?.setting?.isactive === true ? 'aktiv' : 'gesperrt',
        };
    }
}
