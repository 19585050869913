import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LCMSConstants } from '../constants/lcms.constants';
import { StaticHelp } from '../models/static-help.interface';
import { RESTService } from './rest/rest.service';

@UntilDestroy ( { checkProperties: true } )
@Injectable ( {
    providedIn: 'root',
} )
export class _staticHelpService {

    /**
     * Alle Hilfeeinträge im System
     */
    readonly staticHelps: BehaviorSubject<StaticHelp[]> = new BehaviorSubject<StaticHelp[]> ( [] );

    /**
     * Aktuell ausgewählte Hilfekategorie
     */
    readonly staticHelp: BehaviorSubject<StaticHelp | undefined> = new BehaviorSubject<StaticHelp | undefined> ( undefined );

    constructor(
        private rest: RESTService,
    ) {

    }

    /**
     * Lade Hilfekategorien
     */
    fetchHelps(): Observable<StaticHelp[]> {
        return this.rest.addRequest ( {
            method  : RESTService.GET,
            endpoint: LCMSConstants.HELP,
        } )
                   .pipe (
                       tap ( help => this.staticHelps.next ( help ) ),
                   )
    }

    /**
     * Füge neue Hilfekategorie hinzu
     * @param helpLabel
     */
    addHelp( helpLabel: any ): Observable<any> {
        return this.rest.addRequest ( {
            method  : RESTService.POST,
            endpoint: LCMSConstants.HELP,
            payload : {
                label  : helpLabel,
                payload: {
                    title  : helpLabel,
                    entries: [],
                },
            },
        } ) as Observable<any>;
    }

    /**
     * Lade Details einer Kategorie
     * @param id
     */
    loadHelpDetails( id: number ): Observable<any> {
        return this.rest.addRequest ( {
            method  : RESTService.GET,
            endpoint: LCMSConstants.HELP + '/' + id,
        } )
                   .pipe (
                       tap ( value => this.staticHelp.next ( value ) ),
                   ) ;
    }

    /**
     * Speichere Hilfekategorie
     * @param help
     */
    // TODO refactor to id as param
    saveHelp( help: StaticHelp ): Observable<any> {
        return this.rest.addRequest ( {
            method  : RESTService.PUT,
            endpoint: LCMSConstants.HELP + '/' + help.id,
            payload : help,
        } ) as Observable<any>;
    }

    /**
     * Lösche Hilfekategorie
     * @param help
     */
     // TODO refactor to id as param
    deleteHelp( help: StaticHelp ): Observable<any> {
        return this.rest.addRequest ( {
            method  : RESTService.DELETE,
            endpoint: LCMSConstants.HELP + '/' + help.id,
        } ) as Observable<any>;
    }

}
