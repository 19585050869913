import {Injectable} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {LCMSConstants} from '../constants/lcms.constants';
import {UserGroup} from '../models/user.group.interface';
import {User} from '../models/user.interface';
import {BaseService} from './base-service';
import {RESTService} from './rest/rest.service';
import {Tag} from '../models/tag.interface';
import {HttpClient} from '@angular/common/http';

export interface HttpBaseResponse {
  success: boolean;
  pagination: {
    page_count: number;
    current_page: number;
    has_next_page: boolean;
    has_prev_page: boolean;
    count: number;
    limit?: number;
    per_page: number;
  }
}

export interface HttpSingleResponse<T> extends HttpBaseResponse {
  data: T;
}

export interface HttpGroupResponse<T> extends HttpBaseResponse {
  data: T[];
}

@UntilDestroy({checkProperties: true})
@Injectable({
  providedIn: 'root',
})
export class UserService implements BaseService {

  /**
   * Verfügbare Rollen
   */
  static ROLES = [
    {
      label: 'Superadmin', // Name der Rolle
      value: 1, // Numerischer Wert innerhalb des Systems
      disabled: false, // Verfügbarkeit der Rolle. Ein "Firmenadmin" kann bspw. keinen Superadmin anlegen etc.
    },
    {
      label: 'Portaladmin',
      value: 2,
      disabled: false,
    },
    {
      label: 'Firmenadmin',
      value: 3,
      disabled: false,
    },
    {
      label: 'Lerner',
      value: 4,
      disabled: false,
    },
  ];

  /**
   * Aktuell ausgewählte Nutzer
   */
  readonly user: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined);
  /**
   * Alle aktiven Nutzer des Systems
   */
  readonly users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  /**
   * Eingeladene Nutzer
   */
  readonly invitedUsers: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);
  /**
   * Kumulierte Nutzer, Aktiv und Invited
   */
  readonly totalUsers: Observable<User[]>;

  /**
   * Aktuell ausgewählte Gruppe
   */
  readonly group: BehaviorSubject<UserGroup | undefined> = new BehaviorSubject<UserGroup | undefined>(undefined);
  /**
   * All Verfügbaren Gruppen
   */
  readonly userGroups: BehaviorSubject<UserGroup[]> = new BehaviorSubject<UserGroup[]>([]);

  /**
   * Alle Nutzer der aktuel ausgewählten Firma
   */
  readonly companyUsers: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

  /**
   * Eigentlich Legacy. Hier hatten wir ja in der Vergangenheit mal Tags die jedem Nutzer zugewiesen werden konnten und
   * aus der Kombination ergaben sich dann Gruppen. Schwachsinniges System. Wurde ja durch richtige Gruppen ersetzt
   *
   */
  readonly tags: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(
    private rest: RESTService,
    private http: HttpClient,
  ) {
    this.totalUsers = combineLatest([
      this.users,
      this.invitedUsers,
    ])
      .pipe(
        map(([users, invited]) => {
          return users.concat(invited);
        }),
      );
  }

  /**
   * Lade aktive User
   */
  fetchUsers(filter?: any, newUsage?: boolean): Observable<HttpGroupResponse<User>> {
    let endpoint = LCMSConstants.USERS;

    if (filter) {
      endpoint += filter;
    }

    if (newUsage) {
      return this.http.get<HttpGroupResponse<User>>(endpoint);
    } else {
      return this.rest.addRequest({
        method: RESTService.GET,
        endpoint: LCMSConstants.USERS,
      })
        .pipe(
          tap(users => {
            this.users.next(users);
          }),
          tap(users => {
            let tmp: string[] = [];
            /*users.forEach((usr) => {
              tmp = tmp.concat(usr.tags);
            });*/
            this.tags.next(Array.from(new Set(tmp)));
          }),
        );
    }
  }

  /**
   * Lade eingeladene User
   */
  fetchInvitedUsers(): Observable<User[]> {
    return this.rest.addRequest({
      method: RESTService.GET,
      endpoint: LCMSConstants.USERS + '?invited=1',
    })
      .pipe(
        tap(users => {
          users.forEach((user: any) => user.invitation = true);
        }),
        tap(users => this.invitedUsers.next(users)),
      );
  }

  /**
   * Lade Nutzer einer bestimmten Firma
   * @param companyID
   */
  fetchUsersCompany(companyID: any): Observable<User[]> {
    return this.rest.addRequest({
      method: RESTService.GET,
      endpoint: LCMSConstants.USERS + '?organisation_id=' + companyID,
    })
      .pipe(
        tap(users => this.companyUsers.next(users)),
      ) as Observable<User[]>;
  }

  /**
   * Lade NUtzergruppen
   */
  fetchGroups(): Observable<UserGroup[]> {
    return this.rest.addRequest({
      method: RESTService.GET,
      endpoint: LCMSConstants.GROUPS,
    })
      .pipe(
        tap(groups => this.userGroups.next(groups)),
      ) as Observable<UserGroup[]>;
  }

  /**
   * Setze Nutzer aktiv/inaktiv
   * @param state
   * @param user
   */
  setUserActive(state: any, user: any): void {
    user.setting.isactive = state;
    if (user.hasOwnProperty('isActive')) {
      user.isActive = state ? 'aktiv' : 'gesperrt';
    }

    this.rest.addRequest({
      method: RESTService.PUT,
      endpoint: LCMSConstants.USERS + '/' + user.id,
      payload: user,
    })
      .subscribe({
        next: (response) => {
          // Wenn der Nuzer aktuell ausgewählt ist, lade seine Details um sicherzugehen
          if (this.user?.value && this.user.value.id === user.id) {
            this.loadDetails(user.id)
              .subscribe(() => {
              });
          }
        },
        error: () => {
          // Ein Nutzer kann sich nicht selbst deaktivieren
          user.setting.isactive = !state;
        }
      });
  }

  /**
   * Setze Nutzer aktiv/inaktiv
   * @param state
   * @param id
   */
  setUserActiveState(user: User) {
    const tmpUser = {...user, setting: {...user.setting, isactive: !user.setting.isactive}};
    return this.http.put(LCMSConstants.USERS + '/' + user.id, tmpUser);
  }

  /**
   * Aktiviere/Deaktiviere Usergroup
   * @param state
   * @param userGroup
   */
  setUserGroupActive(state: any, userGroup: any): void {
    userGroup.setting.isactive = state;
    this.rest.addRequest({
      method: RESTService.PUT,
      endpoint: LCMSConstants.GROUPS + '/' + userGroup.id,
      payload: userGroup,
    })
      .subscribe((response) => {
        // Wenn es um die aktuelle Gruppe geht, lade ihre details erneut
        if (this.group?.value?.id === userGroup.id) {
          this.loadGroupDetails(userGroup.id)
            .subscribe(() => {
            });
        }
      });
  }

  /**
   * Lade Nutzerdetails
   * @param id
   */
  loadDetails(id: string | number): Observable<User> {
    return this.rest.addRequest({
      method: RESTService.GET,
      endpoint: LCMSConstants.USERS + '/' + id,
    })
      .pipe(
        tap(user => this.user.next(user)),
      ) as Observable<User>;
  }

  /**
   * Speichere Nutzerdetails
   * @param user
   */
  saveDetails(user: any): Observable<any> {
    return this.rest.addRequest({
      method: RESTService.PUT,
      endpoint: LCMSConstants.USERS + '/' + user.id,
      payload: user,
    })
      .pipe(
      ) as Observable<any>;
  }

  /**
   * Lade Benutzer ein
   * @param data
   */
  inviteUsers(data: any): Observable<any> {
    return this.rest.addRequest({
      method: RESTService.POST,
      endpoint: LCMSConstants.INVITE,
      payload: data,
    });
  }

  /**
   * Gib alle Gruppen für einen Nutzer zurück
   * @param id
   * @param tags
   */
  getGroupsForUser(id: any, tags?: any): any {

    const u = this.users.value.concat(this.invitedUsers.value)
      .find(usr => usr.id === id);
    const groupdata = {
      groups: [],
      freetags: [],
    };

    const ctag = tags ? tags : u?.tags;

    const used: any = [];
    for (const g of this.userGroups.value.filter(grp => grp.organisation.id === u?.organisation_id)) {
      if (g.users?.find((usr) => usr.id === u?.id)) {
        // TODO
        // @ts-ignore
        groupdata.groups.push(g);
      }
    }

    ctag.forEach((t: any) => {
      if (used.includes(t)) {

      } else {
        // TODO
        // @ts-ignore
        groupdata.freetags.push(t);
      }
    });
    return groupdata;
  }

  /**
   * Lade Grupendetails
   * @param id
   */
  loadGroupDetails(id: any): Observable<UserGroup> {
    return this.rest.addRequest({
      method: RESTService.GET,
      endpoint: LCMSConstants.GROUPS + '/' + id,
    })
      .pipe(
        tap(group => this.group.next(group)),
      );
  }

  /**
   * Lösche Nutzer
   * @param data
   */
  deleteUser(data: any): Observable<any> {
    return this.rest.addRequest({
      method: RESTService.DELETE,
      endpoint: LCMSConstants.USERS + '/' + data.id,
    }) as Observable<any>;
  }

  /**
   * Einladung erneut versenden
   * @param id
   */
  reinvite(id: number): Observable<any> {
    return this.rest.addRequest({
      method: RESTService.GET,
      endpoint: LCMSConstants.INVITE + '/' + id,
    }) as Observable<any>;
  }

  deleteEntity(entity: any): Observable<any> {
    return this.deleteUser(entity);
  }

  addEntity(entity: any): Observable<any> {
    return this.inviteUsers(entity)
  }
}
