import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LCMSConstants } from '../constants/lcms.constants';
import { Provider } from '../models/provider.interface';
import { BaseService } from './base-service';
import { RESTService } from './rest/rest.service';

@UntilDestroy ( { checkProperties: true } )
@Injectable ( {
    providedIn: 'root',
} )
export class ProvidersService implements BaseService {

    readonly providers: BehaviorSubject<Provider[]> = new BehaviorSubject<Provider[]> ( [] );

    readonly provider: BehaviorSubject<Provider | undefined> = new BehaviorSubject<Provider | undefined> ( undefined );

    constructor(
        private rest: RESTService,
    ) {
    }

    fetchProviders(): Observable<Provider[]> {
        return this.rest.addRequest ( {
            method  : RESTService.GET,
            endpoint: LCMSConstants.PROVIDERS,
        } )
                   .pipe (
                       map ( ( arr ) => arr.sort ( ( a: Provider, b: Provider ) => a.id > b.id ? 1 : - 1 ) ),
                       tap ( providers => {
                           this.providers.next ( providers );
                       } ),
                   );
    }

    loadDetails( id: number | string ): Observable<Provider> {
        return this.rest.addRequest ( {
            method  : RESTService.GET,
            endpoint: LCMSConstants.PROVIDERS + '/' + id,
        } )
                   .pipe (
                       tap ( provider => this.provider.next ( provider ) ),
                   );
    }

    saveDetails( provider: Provider ): Observable<any> {
        return this.rest.addRequest ( {
            method  : RESTService.PUT,
            endpoint: LCMSConstants.PROVIDERS + '/' + provider.id,
            payload : provider,
        } );
    }

    addProvider( label: string ): Subject<any> {
        return this.rest.addRequest ( {
            method  : RESTService.POST,
            endpoint: LCMSConstants.PROVIDERS,
            payload : {
                label,
            },
        } );
    }

    deleteProvider( provider: Provider ): Observable<any> {
        return this.rest.addRequest ( {
            method  : RESTService.DELETE,
            endpoint: LCMSConstants.PROVIDERS + '/' + provider.id,
        } );
    }

    /**
     * get a list of all providers for usage in FieldTypes.SELECTION
     * i.e.:
     * [
     * {
     *   label : "Keiner",
     *   value : null
     * },
     * {
     *   label : "Anbieter 1",
     *   value : 1
     * },
     * ]
     */
    getListOfProviders(): { label: string, value: null | number }[] {
        let providers: { label: string; value: null | number }[] = [];
        providers.push ( {
            label: 'Keiner',
            value: null,
        } );
        providers = providers.concat ( this.providers.value.map ( ( provider: Provider ) => {
                    return {
                        label: provider.label + ' (#' + provider.id + ')',
                        value: provider.id,
                    };
                },
            ),
        );
        return providers;
    }

    deleteEntity( entity: any ) {
        return this.deleteProvider(entity)
    }

    addEntity( entity: any ) {
        return this.addProvider(entity.textinput)
    }

}
