import {LOCALE_ID, NgModule} from "@angular/core";
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import {MatIconModule, MatIconRegistry} from "@angular/material/icon";
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from "@angular/material/snack-bar";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmDialogModule } from './reusable-components/confirm-dialog/confirm-dialog.module';
import { TopBarModule } from './reusable-components/top-bar/top-bar.module';
import { MomentUtcDateAdapter } from './utility/classes/moment-utcdate-adapter';
import { RestServiceModule } from './utility/services/rest/rest-service.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getGermanPaginatorIntl } from './utility/localization/german-paginator-intl';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { extModules } from '../environments/build-specifics';
import { EffectsModule } from '@ngrx/effects';
import { userReducer } from './_root-store/user-store/user.reducer';
import { groupReducer } from './_root-store/group-store/group.reducer';
import { GroupEffects } from './_root-store/group-store/group.effects';
import { companyReducer } from './_root-store/company-store/company.reducer';
import { CompanyEffects } from './_root-store/company-store/company.effects';
import { ReactiveFormsModule } from '@angular/forms';
import {registerLocaleData} from "@angular/common";
import localeDe from '@angular/common/locales/de';

@NgModule( {
    declarations: [
        AppComponent
    ],
    imports     : [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        RestServiceModule,
        MatSidenavModule,
        MatListModule,
        MatSnackBarModule,
        MatIconModule,
        MatButtonModule,
        TopBarModule,
        ConfirmDialogModule,
        MatMomentDateModule,
        QuillModule.forRoot ( {
            theme  : 'snow',
            modules: {
                toolbar: [
                    [
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                    ],
                    [
                        'blockquote',
                        'code-block',
                    ],
                    [
                        { list: 'ordered' },
                        { list: 'bullet' },
                    ],
                    [
                        { script: 'sub' },
                        { script: 'super' },
                    ],
                    [
                        { indent: '-1' },
                        { indent: '+1' },
                    ],
                    [
                        { direction: 'rtl' },
                    ],
                    [{ align: [] }],
                    ['clean'],
                    [
                        'link',
                        'image',
                        'video',
                    ],
                ],
            },
        } ),
        StoreModule.forRoot({ group: groupReducer, company: companyReducer }, {}),
        extModules,
        EffectsModule.forRoot([GroupEffects, CompanyEffects]),
    ],
    providers   : [
        { provide: LOCALE_ID, useValue: 'de-DE' },
        { provide: MAT_DATE_LOCALE, useValue: 'de-DE' },
        { provide: DateAdapter, useClass: MomentUtcDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() },
        { provide: MAT_CHIPS_DEFAULT_OPTIONS, useValue: { separatorKeyCodes: [ENTER, COMMA] }},
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } }
    ],
    bootstrap   : [AppComponent],
})
export class AppModule {
  constructor(private iconRegistry: MatIconRegistry) {
    registerLocaleData(localeDe);

    iconRegistry.setDefaultFontSetClass('material-icons-outlined');
  }
}
