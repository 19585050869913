export interface Tracking {
  /**
   * ID
   */
  id: number;
  /**
   * ID des Nutzers dem dieses TrackingInterface zugewiesen ist
   */
  user_id: number;
  /**
   * KursID zu dem dieses TrackingInterface gehört
   */
  lesson_id: number;
  /**
   * Status des trackings als String
   */
  completion: string;
  /**
   * Bestanden?
   */
  success: string;
  /**
   * Punktzahl
   */
  score: number;
  /**
   * Gesamtdauer
   */
  totaltime: number;
  /**
   * Anzahl versuche
   */
  startcount: number;
  /**
   * Im erssten versuch bestanden?
   */
  firstpassed?: any;
  /**
   * erzeugt durch
   */
  created_by?: any;
  /**
   * Bearbeitet durch
   */
  modified_by?: any;
  /**
   * aktuelle Seitenummer
   */
  currentpage: number;

  /**
   * Fortschritt in Prozent
   */
  progressinpercent: number;
}
