import { companyAdapter, CompanyState, initialCompanyState } from './company.state';
import {CompanyActionType} from './company.actions';

export function companyReducer(state = initialCompanyState, action: any): CompanyState {
  switch (action.type) {

    case CompanyActionType.CompaniesLoading: {
      return {
        ...state,
        loading: true
      };
    }

    case CompanyActionType.CompaniesLoadSuccess: {
      return companyAdapter.setAll(action.payload.data, {
        ...state,
        error: false,
        loading: false,
        total: action.payload.pagination.count,
      });
    }

    case CompanyActionType.CompaniesLoadFailure: {
      return companyAdapter.removeAll({
        ...state,
        error: true,
        loading: false,
        total: 0
      });
    }

    case CompanyActionType.CompaniesClear: {
      return companyAdapter.removeAll({
        ...state,
        error  : false,
        loading: false,
        total  : 0,
        page   : {
          pageIndex: 0,
          pageSize : 25,
          length   : 0,
        },
        terms   : [],
        sort   : null
      });
    }

    case CompanyActionType.CompaniesSetPage: {
      return {
        ...state,
        page: action.page
      };
    }

    case CompanyActionType.CompaniesSetTerm: {
      return {
        ...state,
        terms: action.terms,
        page: {
          ...state.page,
          pageIndex: 0,
        }
      };
    }

    case CompanyActionType.CompaniesSetSort: {
      return {
        ...state,
        page   : {
          ...state.page,
          pageIndex: 0,
        },
        sort: action.sort
      };
    }

    default:
      return state;
  }
}
