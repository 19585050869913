import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LCMSConstants } from '../constants/lcms.constants';
import { BoxDefinition } from '../models/box-definition.interface';
import { RESTService } from './rest/rest.service';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/user.interface';
import { HttpGroupResponse } from './user.service';
import { Page, PageState } from '../../_root-store/page-store/page.state';

@UntilDestroy ( { checkProperties: true } )
@Injectable ( {
    providedIn: 'root',
} )
export class StaticsService {
    /**
     * Alles statischen Seiten im System
     * TODO: Enthält noch viele anys
     */
    readonly pages: BehaviorSubject<any[]> = new BehaviorSubject<any[]> ( [] );

    /**
     * Aktuell ausgewählte statische Seite
     */
    readonly page: BehaviorSubject<any> = new BehaviorSubject<any> ( undefined );

    /**
     * Alle Verfügbaren Boxen. Eine Box ist das was einer Seite hinzugefügt werden kann
     */
    boxes!: Map<string, BoxDefinition>;

    constructor(
        private rest: RESTService,
        private http: HttpClient
    ) {
        this.rest.addRequest ( {
            method  : RESTService.GET,
            endpoint: '/assets/boxes.json',
        } )
            .subscribe ( ( data ) => {
                this.boxes = new Map<string, BoxDefinition> ();
                for ( const key in data ) {
                    if ( data[ key ] ) {
                        this.boxes.set ( key, data[ key ] );
                    }
                }
            } );
    }

    /**
     * Lade alle Seiten
     */
     // TODO: pages interface anlegen
    fetchPages(): Observable<any[]> {
        return this.rest.addRequest ( {
            method  : RESTService.GET,
            endpoint: LCMSConstants.PAGES,
        } )
                   .pipe (
                       map ( ( arr ) => arr.sort ( ( a: any, b: any ) => a.label > b.label ? 1 : - 1 ) ),
                       tap ( help => this.pages.next ( help ) ),
                   );
    }

  /**
   * Lade alle Seiten
   */
  fetchPagesState(filter?: string): Observable<HttpGroupResponse<Page>> {
    let endpoint = LCMSConstants.PAGES;

    if (filter) {
      endpoint += filter;
    }

    return this.http.get<HttpGroupResponse<Page>>(endpoint);
  }

    /**
     * Aktiviere/Deaktiviere Seite
     * @param state
     * @param page
     */
    setPageActive( state: boolean, page: any ): void {
        page.setting.isactive = state;
        if ( page.hasOwnProperty ( 'isActive' ) ) {
            page.isActive = page.setting.isactive ? 'aktiv' : 'gesperrt';
        }

        this.rest.addRequest ( {
            method  : RESTService.PUT,
            endpoint: LCMSConstants.PAGES + '/' + page.id,
            payload : page,
        } )
            .subscribe ( ( response ) => {

                // Sollte die seite aktuell ausgewählt sein, lade sicherheitshalber ihre Details erneut
                if ( this.page.value?.id === page.id ) {
                    this.loadPageDetails ( page.id )
                        .subscribe ( () => {
                        } );
                }
            } );
    }

  /**
   * Setze Seite aktiv/inaktiv
   * @param page
   */
  setPageActiveState( page: Page ) {
    const tmpPage = {...page, setting: {...page.setting, isactive: !page.setting.isactive}};

    return this.http.put(LCMSConstants.PAGES + '/' + page.id, tmpPage);
  }

    /**
     * Seitendetails laden
     * @param id
     */
    loadPageDetails( id: string | number ): Observable<any> {
        return this.rest.addRequest ( {
            method  : RESTService.GET,
            endpoint: LCMSConstants.PAGES + '/' + id,
        } )
                   .pipe (
                       tap ( page => this.page.next ( page ) ),
                   ) as Observable<any>;
    }

    /**
     * Seite speichern
     * @param data
     */
    savePage( data: any ): Observable<any> {
        return this.rest.addRequest ( {
            method  : RESTService.PUT,
            endpoint: LCMSConstants.PAGES + '/' + data.id,
            payload : data,
        } ) as Observable<any>;
    }

    /**
     * Seite hinzufügen
     * @param label
     * @param language
     */
    addPage( label: string, language: string ): Subject<any> {
        return this.rest.addRequest ( {
            method  : RESTService.POST,
            endpoint: LCMSConstants.PAGES,
            payload : {
                label,
                language,
                payload: {
                    label,
                    blocks: [],
                    id    : label,
                },
                setting: {
                    isactive: false,
                },
            },
        } ) as Subject<any>;
    }

    /**
     * Seite Löschen
     * @param page
     */
    deletePage( page: any ): Subject<any> {
        return this.rest.addRequest ( {
            method  : RESTService.DELETE,
            endpoint: LCMSConstants.PAGES + '/' + page.id,
        } ) as Subject<any>;
    }

    /**
     * Seite kopieren
     * @param label
     * @param language
     * @param page
     */
    clonePage( label: string, language: string, page: any ): Subject<any> {
        return this.rest.addRequest ( {
            method  : RESTService.PUT,
            endpoint: LCMSConstants.CLONE_PAGE + page.id,
            payload : {
                label,
                language,
            },
        } ) as Subject<any>;
    }

}
