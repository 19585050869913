import { PageEvent } from '@angular/material/paginator/paginator';
import { Sort } from '@angular/material/sort/sort';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { Group, groupAdapter, GroupState } from './group.state';

export const {
               selectIds     : _selectGroupDataIds,
               selectEntities: _selectGroupEntities,
               selectAll     : _selectAllGroups,
               selectTotal   : _selectGroupTotal,
             } = groupAdapter.getSelectors ();

export const selectGroupState = createFeatureSelector<GroupState> ( 'group' );

export const selectGroupIds = createSelector (
  selectGroupState,
  _selectGroupDataIds,
);

export const selectGroupEntities = createSelector (
  selectGroupState,
  _selectGroupEntities,
);

export const selectAllGroups = createSelector (
  selectGroupState,
  _selectAllGroups,
);

export const selectGroupError = createSelector (
  selectGroupState,
  ( state: GroupState ): boolean => state.error,
);

export const selectGroupLoading = createSelector (
  selectGroupState,
  ( state: GroupState ): boolean => state.loading,
);

export const selectGroupTotal = createSelector (
  selectGroupState,
  ( state: GroupState ): number => state.total,
);

export const selectGroupPage = createSelector (
  selectGroupState,
  ( state: GroupState ): PageEvent => state.page,
);

export const selectGroupTerm = createSelector (
  selectGroupState,
  ( state: GroupState ): string => state.term,
);

export const selectGroupSort = createSelector (
  selectGroupState,
  ( state: GroupState ): Sort => state.sort as Sort,
);

export const selectGroupById = (id: number) => createSelector(
  selectGroupEntities,
  entities => entities[id]
)


export const selectGroupSearchRequest = createSelector (
  selectGroupState,
  ( state: GroupState ) => {
    let urlParameters = "?";

    urlParameters += ("limit=" + state.page.pageSize);
    urlParameters += ("&page=" + (state.page.pageIndex + 1));

    if (state.sort) {
      switch (state.sort.active) {
        case 'isActive': {
          urlParameters += ("&sort=" + 'Settings.isactive' + "&direction=" + state.sort.direction);
          break;
        }

        case 'organisation': {
          urlParameters += ("&sort=" + 'Organisations.label' + "&direction=" + state.sort.direction);
          break;
        }

        default: {
          urlParameters += ("&sort=" + state.sort.active + "&direction=" + state.sort.direction);
          break;
        }
      }
    } else {
      urlParameters += ("&sort=id" + "&direction=asc");
    }

    return urlParameters;
  },
);
