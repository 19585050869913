import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, of, Subject} from "rxjs";
import {HttpGroupResponse, HttpSingleResponse} from "./user.service";
import {Sponsor} from "../../_root-store/sponsor-store/sponsor.state";
import {LCMSConstants} from "../constants/lcms.constants";
import {RESTService} from "./rest/rest.service";

@Injectable({
  providedIn: 'root'
})
export class SponsorService {

  constructor(private http: HttpClient) {}

  fetchSponsors(searchRequests?: string): Observable<HttpGroupResponse<Sponsor>> {
    const urlParams = searchRequests ?? '';

    return this.http.get<HttpGroupResponse<Sponsor>>(LCMSConstants.SPONSORS + urlParams);
  }

  loadSponsorDetails(id: number | string): Observable<HttpSingleResponse<Sponsor>> {
    return this.http.get<HttpSingleResponse<Sponsor>>(LCMSConstants.SPONSORS + '/' + id);
  }

  setSponsorActive(sponsor: Sponsor): Observable<any> {
    const toggledSponsor: Sponsor = {
      ...sponsor,
      setting: {
        ...sponsor.setting,
        isactive: !sponsor.setting.isactive
      }
    };

    return this.http.put(LCMSConstants.SPONSORS + '/' + sponsor.id, toggledSponsor);
  }

  saveSponsorDetails(sponsor: Sponsor) {
    return this.http.put(LCMSConstants.SPONSORS + '/' + sponsor.id, sponsor);
  }

  addSponsor( label: string ) {
    return this.http.post(LCMSConstants.SPONSORS, {label, uname: encodeURI(label.toLowerCase()), setting: { isactive: false }});
  }

  deleteSponsor(sponsor: Sponsor) {
    return this.http.delete(LCMSConstants.SPONSORS + '/' + sponsor.id);
  }
}
