import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Injectable ( {
  providedIn: 'root'
} )
export class SystemService {

  /**
   * Zeigt an, ob das aktuell ausgewählte Element isactive true oder false hat, um in der AppComponent ggf
   * dies Grafisch anzuzeigen. Wird bei jedem navigationsvorgang kategorisch zurück  gesetzt
   */
  readonly elementInactive: BehaviorSubject<boolean> = new BehaviorSubject<boolean> ( false );

  /**
   * Gibt an ob aktuell ein Dialog offen ist, für fancy blur effekt
   */
  readonly dialogOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean> ( false );


  constructor(
    private router: Router
  ) {
    this.router.events
        .pipe (
          filter ( event => event instanceof NavigationStart )
        )
        .subscribe ( ( event ) => {
          this.elementInactive.next ( false );
        } );
  }
}
