import {environment} from "../../../environments/environment";

export class LCMSConstants {
  /**
   * Backend URL
   */
  static readonly API = environment.server.api;

  /**
   * Nutzergruppen
   */
  static readonly GROUPS = LCMSConstants.API + '/usergroups';
  /**
   * Nutzer
   */
  static readonly USERS = LCMSConstants.API + '/users';
  /**
   * Einladungen
   */
  static readonly INVITE = LCMSConstants.USERS + '/invite';
  /**
   * Login
   */
  static readonly LOGIN = LCMSConstants.USERS + '/token';

  /**
   * Firmen
   */
  static readonly COMPANIES = LCMSConstants.API + '/organisations';

  /**
   * Einladungscodes
   */
  static readonly CODES = LCMSConstants.API + '/codes';

  /**
   * Kurse
   */
  static readonly LESSONS = LCMSConstants.API + '/lessons';

  /**
   * Kurskategorien
   */
  static readonly LESSON_CATEGORIES = LCMSConstants.API + '/lessoncategories';

  /**
   * Kursaktualisierungen
   */
  static readonly LESSONS_UPDATE = LCMSConstants.LESSONS + '/update-content/';

  /**
   * Kurspakete
   */
  static readonly LESSONBUNDLES = LCMSConstants.API + '/lessonbundles';
  /**
   * Lernpfade
   */
  static readonly LESSONPATHS = LCMSConstants.API + '/lessonpaths';

  /**
   * Sponsoren
   */
  static readonly SPONSORS = LCMSConstants.API + '/sponsors';

  /**
   * Glossareinträge
   */
  static readonly GLOSSARY = LCMSConstants.API + '/glossaryterms';
  /**
   * Neuigkeiten
   */
  static readonly NEWS = LCMSConstants.API + '/posts';
  /**
   * Hilfekategorien
   */
  static readonly HELP = LCMSConstants.API + '/statichelps';
  /**
   * Systemtexte
   */
  static readonly TEXTS = LCMSConstants.API + '/internationalisations';
  /**
   * Systemtexte Bulkedit
   */
  static readonly TEXT_SAVE = LCMSConstants.API + '/internationalisations/editall';

  /**
   * Static pages
   */
  static readonly PAGES = LCMSConstants.API + '/staticpages';
  /**
   * Seite kopieren
   */
  static readonly CLONE_PAGE = LCMSConstants.PAGES + '/duplicate/';

  /**
   * Standorte
   */
  static readonly LOCATIONS = LCMSConstants.API + '/locations';

  /**
   * Räume
   */
  static readonly ROOMS = LCMSConstants.API + '/rooms';


  /**
   * Bilder
   */
  static readonly MEDIAITEMS = LCMSConstants.API + '/mediaitems';

  static readonly AUTHORS = LCMSConstants.API + '/authors';

  static readonly PROVIDERS = LCMSConstants.API + '/providers';

  static readonly TAGS = LCMSConstants.API + '/tags?model=lessons';

  /**
   * Statistik-Daten
   */
  static readonly STATISTICS = LCMSConstants.API + '/statistics';

  static readonly STATISTICS_v2 = LCMSConstants.API + '/v2/statistics';
}
