import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { of, tap } from 'rxjs';
import {catchError, map, switchMap, take, withLatestFrom} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';

import {
  COMPANIES_LOAD_ACTION,
  COMPANIES_LOAD_FAIL_ACTION,
  COMPANIES_LOAD_SUCCESS_ACTION,
  CompanyActionType
} from './company.actions';
import {Company} from './company.state';
import { selectCompanySearchRequest } from './company.selector';
import { CompanyService } from '../../utility';
import { AppState } from '../app.state';
import { OrganisationMapperService } from '../../utility/services/organisation.mapper.service';
import { UserActionType, USERS_LOAD_ACTION } from '../user-store/user.actions';

@Injectable({
  providedIn: 'root'
})
export class CompanyEffects {
  public loadCompanies$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(CompanyActionType.CompaniesLoading),
        switchMap(() =>
          this.store.pipe(
            select(selectCompanySearchRequest),
            take(1),
            switchMap(searchRequest => {
                return this.companyService.fetchCompaniesState(searchRequest).pipe(
                  map(response => {
                    const enrichedCompanyArray: Company[] = [];

                    for (const company of response.data) {
                      enrichedCompanyArray.push(this.companyMapper.mapToOrganisationTableInput(company));
                    }

                    return {
                      ...response,
                      data: enrichedCompanyArray,
                    };
                  }),
                  map((response) => COMPANIES_LOAD_SUCCESS_ACTION({
                    payload: response
                  })),
                );
              }
            )
          )
        ),
        catchError((error) => of(COMPANIES_LOAD_FAIL_ACTION(error)))
      );
  });

  public setPage$ = createEffect(() => {
    return this.actions$
      .pipe(
        ofType(
          CompanyActionType.CompaniesSetPage,
          CompanyActionType.CompaniesSetTerm,
          CompanyActionType.CompaniesSetSort,
        ),
        map(action => COMPANIES_LOAD_ACTION())
      );
  });

    public toggleActive$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(
                    CompanyActionType.CompaniesToggleActive,
                ),
                switchMap((action: any) => {
                    console.log(action);
                    return this.companyService.setCompanyActiveState(action.company).pipe(
                        map((response: any) => {
                            return COMPANIES_LOAD_ACTION();
                        })
                    );
                }),
            );
    });

  constructor(private companyService: CompanyService,
              private actions$: Actions,
              private companyMapper: OrganisationMapperService,
              private store: Store<AppState>) {
  }
}
