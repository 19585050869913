export interface Section {
  /**
   * Beschriftung
   */
  label: string;
  /**
   * ID/Key
   */
  id: string;
  /**
   * Anzahl fehlender Übersetzungen in der Sektion
   */
  missing: number;

}
